import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import TaskIcon from 'src/assets/icons/task.svg';

const CARD_IMAGE = 'CARD_IMAGE';
const CUSTOM_TASK_CARD_IMAGE = 'CUSTOM_TASK_CARD_IMAGE';

const UnselectedContainer = styled('div')`
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  margin: 5px;
  align-items: center;
  min-height: 65px;
  width: 90%;
  cursor: pointer;

  :hover {
    width: 92%;
  }
`;

const SelectedContainer = styled('div')`
  display: flex;
  background-color: #ffffff;
  border-radius: 2px;
  margin: 5px;
  align-items: center;
  min-height: 59px;
  width: 90%;
  cursor: pointer;
  border: 3px solid #20b2aa;
`;

const TextContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 75%;
`;

const CardImage = styled('img')`
  height: 100%;
  width: 25%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`;

const CardText = styled(Typography)`
  color: #666666;
`;
CardText.defaultProps = {
  variant: 'body1',
};

const CardImageCustomTask = styled('img')`
  height: 60%;
  width: 60%;
  opacity: 0.7;
`;

const CardImageCustomTaskContainer = styled('div')`
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`;

const ChooseTaskCard = ({
  task,
  setSelectedTask,
  selectedTask,
  setTaskDescription,
  isCustom,
  customTitle,
}) => {
  const Container =
    selectedTask && selectedTask.title === task.title
      ? SelectedContainer
      : UnselectedContainer;

  const cardImage = isCustom ? (
    <CardImageCustomTaskContainer>
      <CardImageCustomTask
        src={TaskIcon}
        data-testid={CUSTOM_TASK_CARD_IMAGE}
      />
    </CardImageCustomTaskContainer>
  ) : (
    <CardImage src={task.image} data-testid={CARD_IMAGE} />
  );

  return (
    <Container
      onClick={() => {
        setSelectedTask(task);
        setTaskDescription(isCustom ? customTitle : task.title);
      }}
    >
      {cardImage}
      <TextContainer>
        <CardText>{task.title}</CardText>
      </TextContainer>
    </Container>
  );
};

ChooseTaskCard.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
  }),
  selectedTask: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
  }),
  setSelectedTask: PropTypes.func,
  setTaskDescription: PropTypes.func,
  isCustom: PropTypes.bool,
  customTitle: PropTypes.string,
};

export default ChooseTaskCard;
