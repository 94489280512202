import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Drawer, Typography } from '@material-ui/core';

import {
  VACANCY_STATUS,
  CLEAN_STATUS,
  DO_NOT_DISTURB_STATUS,
} from 'src/models/RoomStore';
import {
  STAYOVER_TEXT,
  VACANT_TEXT,
  CHECKOUT_TEXT,
  BLOCKED_TEXT,
  CLEAN_TEXT,
  DIRTY_TEXT,
  DO_NOT_DISTURB_TEXT,
} from 'src/screens/Dashboard/Rooms/RoomScreen/components/RoomFilters';
import { useStore } from 'src/context/StoreContext';
import { useRoomFilters } from 'src/screens/Dashboard/Rooms/RoomScreen/components/RoomFiltersContext';
import RoomFilterStatusButton from 'src/screens/Dashboard/Rooms/RoomScreen/components/RoomFilterStatusButton';

const Container = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-area: rooms_filters;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  overflow: hidden;
`;

const StyledButton = styled(Button)`
  max-height: 35px;
  width: 100%;
`;

const DrawerContainer = styled('div')`
  height: 50vh;
  max-height: 250px;
  padding: 15px 25px;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  overflow-x: hidden;
  overflow-y: scroll;
`;

const FilterButtonsContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  grid-gap: 10px;
  overflow: scroll;
`;

const RoomFiltersMobile = () => {
  const { roomStore } = useStore();
  const { clearFilters, filters } = useRoomFilters();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isAnyFilterOn =
    Object.values(filters).filter((filterOn) => filterOn).length > 0;

  return (
    <>
      <Container>
        <StyledButton variant="contained" onClick={() => setIsDrawerOpen(true)}>
          Filter
        </StyledButton>
        <StyledButton disabled={!isAnyFilterOn} onClick={clearFilters}>
          Clear Filters
        </StyledButton>
      </Container>

      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        disableSwipeToOpen={false}
      >
        <DrawerContainer>
          <div>
            <Typography
              variant="caption"
              style={{
                fontWeight: 'bold',
              }}
            >
              Vacancy Status
            </Typography>
            <FilterButtonsContainer>
              <RoomFilterStatusButton
                count={roomStore.stayoverRoomCount}
                title={STAYOVER_TEXT}
                filterType={VACANCY_STATUS.STAYOVER}
              />
              <RoomFilterStatusButton
                count={roomStore.vacantRoomCount}
                title={VACANT_TEXT}
                filterType={VACANCY_STATUS.VACANT}
              />
              <RoomFilterStatusButton
                count={roomStore.checkoutRoomCount}
                title={CHECKOUT_TEXT}
                filterType={VACANCY_STATUS.CHECKOUT}
              />
              <RoomFilterStatusButton
                count={roomStore.blockedRoomCount}
                title={BLOCKED_TEXT}
                filterType={VACANCY_STATUS.BLOCKED}
                color="red"
              />
            </FilterButtonsContainer>
          </div>

          <div>
            <Typography
              variant="caption"
              style={{
                fontWeight: 'bold',
              }}
            >
              Clean Status
            </Typography>
            <FilterButtonsContainer>
              <RoomFilterStatusButton
                count={roomStore.cleanRoomCount}
                title={CLEAN_TEXT}
                filterType={CLEAN_STATUS.CLEAN}
                color="green"
              />
              <RoomFilterStatusButton
                count={roomStore.dirtyRoomCount}
                title={DIRTY_TEXT}
                filterType={CLEAN_STATUS.DIRTY}
                color="orange"
              />
              <RoomFilterStatusButton
                count={roomStore.doNotDisturbRoomCount}
                title={DO_NOT_DISTURB_TEXT}
                filterType={DO_NOT_DISTURB_STATUS}
                color="pink"
                style={{
                  minWidth: '145px',
                }}
              />
            </FilterButtonsContainer>
          </div>
        </DrawerContainer>
      </Drawer>
    </>
  );
};

export default RoomFiltersMobile;
