import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import moment from 'moment-timezone';
import styled from '@emotion/styled';

import { VACANCY_STATUS } from 'src/models/RoomStore';

const TableCellStyled = styled(TableCell)`
  padding: 0px;
  margin: 0px;
  padding-left: 25px;
`;

const RoomAssignments = ({
  roomAssignments,
  userStore,
  roomStore,
  hotelStore,
}) => {
  const { users } = userStore;
  const { rooms } = roomStore;
  const { timeZone } = hotelStore;

  const housekeeperPages = [];
  if (roomAssignments && timeZone) {
    const today = moment()
      .tz(timeZone)
      .format('MMMM DD, YYYY');
    const assignmentsByHousekeepers = new Map();

    roomAssignments.forEach((roomAssignment) => {
      if (rooms.get(roomAssignment.roomId)) {
        const { number } = rooms.get(roomAssignment.roomId);
        roomAssignment.number = parseInt(number, 10);
      }
    });

    const roomAssignmentsSorted = roomAssignments.sort(
      (a, b) => parseFloat(a.number) - parseFloat(b.number)
    );

    roomAssignmentsSorted.forEach((roomAssignment) => {
      if (roomAssignment.clearedAt) {
        return;
      }

      if (rooms.get(roomAssignment.roomId)) {
        const { number } = rooms.get(roomAssignment.roomId);
        roomAssignment.number = parseInt(number, 10);
      }

      if (assignmentsByHousekeepers.has(roomAssignment.userId)) {
        assignmentsByHousekeepers
          .get(roomAssignment.userId)
          .push(roomAssignment);
      } else {
        assignmentsByHousekeepers.set(roomAssignment.userId, [roomAssignment]);
      }
    });

    assignmentsByHousekeepers.forEach((assignments, userId) => {
      const firstName = users.get(userId) ? users.get(userId).firstName : '';
      const lastName = users.get(userId) ? users.get(userId).lastName : '';
      const pageContents = [];
      assignments.forEach((assignment) => {
        if (rooms.get(assignment.roomId)) {
          pageContents.push(
            <TableRow key={assignment.id} style={{ padding: 'none' }}>
              <TableCellStyled>
                <Typography
                  variant="p"
                  style={{
                    fontSize: '18px',
                  }}
                >
                  {rooms.get(assignment.roomId).number}
                </Typography>
              </TableCellStyled>
              <TableCellStyled>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight:
                      rooms.get(assignment.roomId).vacancyStatus ===
                      VACANCY_STATUS.VACANT
                        ? '900'
                        : 'regular',
                    fontSize:
                      rooms.get(assignment.roomId).vacancyStatus ===
                      VACANCY_STATUS.VACANT
                        ? '22px'
                        : '20px',
                  }}
                >
                  {rooms.get(assignment.roomId).vacancyStatus ===
                  VACANCY_STATUS.STAYOVER
                    ? 'Stay'
                    : 'C/O'}
                </Typography>
              </TableCellStyled>
              <TableCellStyled>
                <Checkbox />
              </TableCellStyled>
              <TableCellStyled />
            </TableRow>
          );
        }
      });

      housekeeperPages.push(
        <div key={assignments[0].userId} style={{ height: '100vh' }}>
          <div style={{ padding: '40px' }}>
            <div
              style={{
                marginBottom: '30px',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Typography
                variant="p"
                style={{
                  fontSize: '18px',
                }}
              >{`${firstName} ${lastName}`}</Typography>
              <Typography
                variant="p"
                style={{
                  fontSize: '18px',
                }}
              >
                {' '}
                {today}
              </Typography>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: '18px',
                      }}
                    >
                      {' '}
                      Room{' '}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: '18px',
                      }}
                    >
                      {' '}
                      Status{' '}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: '18px',
                      }}
                    >
                      {' '}
                      Clean{' '}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: '18px',
                      }}
                    >
                      {' '}
                      Notes{' '}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{pageContents}</TableBody>
            </Table>
          </div>
        </div>
      );
    });
  }

  return housekeeperPages;
};

RoomAssignments.propTypes = {
  roomAssignments: PropTypes.array,
  userStore: PropTypes.object,
  roomStore: PropTypes.object,
  hotelStore: PropTypes.object,
};

export default RoomAssignments;
