import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

import { INCOMPLETE_STATUS } from 'src/models/TaskStore';
import TaskCard from 'src/screens/Dashboard/Tasks/TaskCard/TaskCard';

const TaskCardContainer = styled('div')`
  width: 90%;
  border-radius: 4px;
  margin-bottom: 15px;
`;

const groupBy = (arr, property) => {
  return arr.reduce((memo, x) => {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
};

const TaskCardsByDescription = ({
  selectedTaskStatus,
  tasksAsArray,
  completedTodayTasks,
  authenticatedUser,
}) => {
  const DESCRIPTION_SECTION_HEADER = 'DESCRIPTION_SECTION_HEADER';
  const languagePreference = authenticatedUser
    ? authenticatedUser.languagePreference
    : null;

  const incompleteTasks = tasksAsArray.filter(
    (task) => task.status === INCOMPLETE_STATUS
  );
  const allTasks = incompleteTasks.concat(completedTodayTasks);

  allTasks.forEach((task) => {
    const descriptionStringified = JSON.stringify(task.description);
    task.descriptionStringified = descriptionStringified;
  });

  const taskDescriptions = Object.keys(
    groupBy(allTasks, 'descriptionStringified')
  );
  const tasksByDescription = taskDescriptions.map((description) => {
    return groupBy(allTasks, 'descriptionStringified')[description];
  });

  const sortDescriptionAlphabetically = (a, b) => {
    return a[0].description[languagePreference].localeCompare(
      b[0].description[languagePreference]
    );
  };
  tasksByDescription.sort(sortDescriptionAlphabetically);

  const descriptionSections = tasksByDescription.map((taskArray) => {
    const tasksForDescription = taskArray.filter(
      (task) => task.status === selectedTaskStatus
    );
    if (tasksForDescription.length > 0) {
      return (
        <React.Fragment
          key={tasksForDescription[0].description[languagePreference]}
        >
          <div style={{ width: '90%', color: '#666666' }}>
            <Typography
              data-testid={DESCRIPTION_SECTION_HEADER}
              style={{ margin: '10px 0px' }}
              variant="h6"
            >
              {tasksForDescription[0].description[languagePreference]}
            </Typography>
          </div>
          {tasksForDescription.map((task) => (
            <TaskCardContainer key={task.id}>
              <TaskCard task={task} showRoomAsTitle />
            </TaskCardContainer>
          ))}
        </React.Fragment>
      );
    }
    return null;
  });

  return descriptionSections;
};

TaskCardsByDescription.propTypes = {
  selectedTaskStatus: PropTypes.string,
  tasksAsArray: PropTypes.array,
  completedTodayTasks: PropTypes.array,
  authenticatedUser: PropTypes.object,
};

export default TaskCardsByDescription;
