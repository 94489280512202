import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import SearchImage from 'src/assets/icons/search.svg';
import { SEARCH_BAR } from './CreateEditTaskStrings';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #8e8e8e;
  width: 100%;
`;

const SearchBarInput = styled(TextField)`
  display: flex;
  background-color: #ffffff;
  font-size: 16px;
  width: 100%;
  margin: 10px;
`;

const SearchIcon = styled('img')`
  width: 20px;
  height: 20px;
`;

const ChooseTaskSearchBar = ({ setSearch, search, isRoomBlock }) => {
  return (
    <Container>
      <SearchBarInput
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon src={SearchImage} />
            </InputAdornment>
          ),
          style: {
            height: '47px',
          },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ 'data-testid': SEARCH_BAR }}
        InputLabelProps={{
          style: {
            height: '47px',
            top: `-4px`,
          },
        }}
        id="outlined-basic"
        label={isRoomBlock ? "What's the reason?" : "What's the task?"}
        variant="outlined"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
    </Container>
  );
};

ChooseTaskSearchBar.propTypes = {
  setSearch: PropTypes.func,
  search: PropTypes.string,
  isRoomBlock: PropTypes.bool,
};

export default ChooseTaskSearchBar;
