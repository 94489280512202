import { types, flow } from 'mobx-state-tree';
import { values } from 'mobx';
import { request } from '../utils/LodgebookAPIClient';

export const TEXT_MESSAGE_TYPES = {
  OUTBOUND_TEXT_MESSAGE: 'OutboundTextMessage',
  INBOUND_TEXT_MESSAGE: 'InboundTextMessage',
};

const TEXT_MESSAGE_URL = '/text_messages';

export const TextMessage = types.model('TextMessage', {
  id: types.identifierNumber,
  guestId: types.maybeNull(types.number),
  userId: types.maybeNull(types.number),
  sentAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  read: types.optional(types.boolean, false),
  type: types.enumeration([
    TEXT_MESSAGE_TYPES.OUTBOUND_TEXT_MESSAGE,
    TEXT_MESSAGE_TYPES.INBOUND_TEXT_MESSAGE,
  ]),
  guest: types.frozen({
    name: types.string,
    roomId: types.number,
  }),
});

const TextMessageStore = types
  .model('TextMessageStore', {
    textMessages: types.optional(types.map(TextMessage), {}),
    isFetchingAll: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
  })
  .views((self) => ({
    get textMessagesAsArray() {
      return values(self.textMessages);
    },
    get textMessagesByGuests() {
      const textMessagesByGuest = new Map();

      values(self.textMessages)
        .sort((a, b) => new Date(b.sentAt) - new Date(a.sentAt))
        .forEach((textMessage) => {
          if (textMessagesByGuest.has(textMessage.guestId)) {
            textMessagesByGuest.get(textMessage.guestId).push(textMessage);
          } else {
            textMessagesByGuest.set(textMessage.guestId, [textMessage]);
          }
        });

      return textMessagesByGuest;
    },
    get numberOfUnreadMessages() {
      let numberOfUnreadMessages = 0;
      values(self.textMessages).forEach((message) => {
        if (
          !message.read &&
          message.type === TEXT_MESSAGE_TYPES.INBOUND_TEXT_MESSAGE
        ) {
          numberOfUnreadMessages++;
        }
      });
      return numberOfUnreadMessages;
    },
  }))
  .actions((self) => ({
    fetchTextMessage: flow(function*(textMessageId) {
      self.networkError = undefined;
      try {
        const { textMessage } = yield request(
          `${TEXT_MESSAGE_URL}/${textMessageId}`,
          'GET'
        );
        self.textMessages.set(textMessage.id, textMessage);
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch text message', error);
      }
    }),
    fetchAllTextMessages: flow(function*(hotelId) {
      self.networkError = undefined;
      self.isFetchingAll = true;
      try {
        const textMessagesResponse = yield request(
          `${TEXT_MESSAGE_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        self.textMessages = {};
        textMessagesResponse.textMessages.forEach((textMessage) => {
          self.textMessages.set(textMessage.id, textMessage);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch text messages', error);
      }
      self.isFetchingAll = false;
    }),
    setTextMessageAsRead: flow(function*(textMessageId) {
      if (
        !self.textMessages.get(textMessageId).read &&
        self.textMessages.get(textMessageId).type ===
          TEXT_MESSAGE_TYPES.INBOUND_TEXT_MESSAGE
      ) {
        const originalTextMessage = {};
        Object.assign(
          originalTextMessage,
          self.textMessages.get(textMessageId)
        );
        try {
          self.textMessages.set(textMessageId, {
            ...originalTextMessage,
            read: true,
          });

          yield request(`${TEXT_MESSAGE_URL}/${textMessageId}`, 'PATCH', {
            body: {
              read: true,
            },
          });
        } catch (error) {
          self.textMessages.set(textMessageId, originalTextMessage);
          console.error('Failed to update text message', error);
        }
      }
    }),
    createTextMessage: flow(function*(options) {
      try {
        const modifiedPayload = {
          ...options.body,
        };
        const textMessageResponse = yield request(TEXT_MESSAGE_URL, 'POST', {
          body: { ...modifiedPayload },
        });
        self.textMessages.set(
          textMessageResponse.textMessage.id,
          textMessageResponse.textMessage
        );
        self.networkError = undefined;
      } catch (error) {
        self.networkError = JSON.stringify(error);
      }
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
  }));

export default TextMessageStore;
