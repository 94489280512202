export const MARK_AS_CLEAN = 'Mark as Clean';
export const MARK_AS_DIRTY = 'Mark as Dirty';
export const BLOCK_ROOM = 'Block Room';
export const UNBLOCK_CLEAN_ROOM = 'Unblock as Clean';
export const UNBLOCK_DIRTY_ROOM = 'Unblock as Dirty';
export const TURN_ON_DO_NOT_DISTURB = 'Do Not Disturb';
export const TURN_OFF_DO_NOT_DISTURB = 'Remove Do Not Disturb';
export const MARK_AS_VACANT = 'Mark as Vacant';
export const MARK_AS_STAYOVER = 'Mark as Stayover';
export const MARK_AS_CHECKOUT = 'Mark as Checkout';
export const CLOSE = 'Close';
