import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Modal, Typography, Button } from '@material-ui/core';
import { ReactComponent as SuccessIcon } from 'src/assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/error.svg';
import {
  NETWORK_CREATE_SUCCESS_TEXT,
  NETWORK_CREATE_FAILURE_TEXT,
  CANCEL_BUTTON_TEXT,
  RETRY_BUTTON_TEXT,
  CANCEL_BUTTON_LOADING_MODAL,
  NETWORK_PATCH_SUCCESS_TEXT,
  NETWORK_PATCH_FAILURE_TEXT,
} from 'src/screens/Dashboard/Tasks/CreateEditTaskScreen/components/CreateEditTaskStrings';

const Container = styled('div')`
  outline: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;
`;

const ButtonsContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const ErrorImage = styled(ErrorIcon)`
  height: 60px;
  width: 60px;
  margin-top: 40px;
  fill: #ff101e;
`;

const SuccessImage = styled(SuccessIcon)`
  height: 60px;
  width: 60px;
  margin-top: 40px;
  fill: #00988f;
`;

const StyledModal = styled(Modal)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
  height: 200px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    width: 25%;
  }
`;

const LoadingModal = ({
  onClose,
  handleGoBack,
  networkError,
  onRetry,
  dismissNetworkError,
  task,
}) => {
  useEffect(() => {
    if (!networkError) {
      setTimeout(handleGoBack, 1000);
    }
  }, []);

  let loadingModalMessage;

  if (task) {
    loadingModalMessage = networkError
      ? NETWORK_PATCH_FAILURE_TEXT
      : NETWORK_PATCH_SUCCESS_TEXT;
  } else {
    loadingModalMessage = networkError
      ? NETWORK_CREATE_FAILURE_TEXT
      : NETWORK_CREATE_SUCCESS_TEXT;
  }

  return (
    <StyledModal open onClose={onClose}>
      <Container>
        <Typography style={{ color: '#666666' }} variant="h6">
          {loadingModalMessage}
        </Typography>
        {networkError ? <ErrorImage /> : <SuccessImage />}
        {networkError ? (
          <ButtonsContainer>
            <Button
              style={{ width: '40%' }}
              color="grey"
              data-testid={CANCEL_BUTTON_LOADING_MODAL}
              onClick={() => {
                dismissNetworkError();
                onClose();
              }}
            >
              {CANCEL_BUTTON_TEXT}
            </Button>
            <Button
              style={{ width: '40%' }}
              variant="contained"
              onClick={(e) => {
                onClose();
                dismissNetworkError();
                onRetry(e);
              }}
            >
              {RETRY_BUTTON_TEXT}
            </Button>
          </ButtonsContainer>
        ) : null}
      </Container>
    </StyledModal>
  );
};

LoadingModal.propTypes = {
  handleGoBack: PropTypes.func,
  onClose: PropTypes.func,
  task: PropTypes.object,
  onRetry: PropTypes.func,
  dismissNetworkError: PropTypes.func,
  networkError: PropTypes.string,
};

export default LoadingModal;
