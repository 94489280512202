import { types, flow } from 'mobx-state-tree';

import { request } from '../utils/LodgebookAPIClient';

export const HOTELS_URL = '/hotels';

const HotelStore = types
  .model('HotelStore', {
    selectedHotelId: types.maybe(types.number),
    isFetchingHotels: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
    timeZone: types.maybe(types.string),
    name: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),
  })
  .actions((self) => ({
    fetchAndSetHotel: flow(function*() {
      self.networkError = undefined;
      self.isFetchingHotels = true;
      try {
        const { hotels } = yield request(HOTELS_URL, 'GET');
        if (hotels.length === 1) {
          self.selectedHotelId = hotels[0].id;
          self.timeZone = hotels[0].timeZone;
          self.name = hotels[0].name;
          self.phoneNumber = hotels[0].phoneNumber;
        } else {
          // eventually we want to let the user choose if there is more than 1 hotel
          self.selectedHotelId = hotels[0].id;
          self.timeZone = hotels[0].timeZone;
        }
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch hotel users', error);
      }
      self.isFetchingHotels = false;
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
  }));

export default HotelStore;
