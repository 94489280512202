import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { InputLabel, FormControl } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import { BLOCK_TOGGLE, BLOCK_LABEL_TEXT } from './CreateEditTaskStrings';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled(InputLabel)`
  width: 100%;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
`;

const CreateEditTaskBlockRoomSwitch = ({ checked, handleChange, disabled }) => {
  return (
    <Container>
      <Label>{BLOCK_LABEL_TEXT}</Label>
      <FormControl>
        <Switch
          data-testid={BLOCK_TOGGLE}
          checked={checked}
          onChange={() => handleChange(!checked)}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          disabled={disabled}
        />
      </FormControl>
    </Container>
  );
};

CreateEditTaskBlockRoomSwitch.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CreateEditTaskBlockRoomSwitch;
