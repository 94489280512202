import React from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/Logout';

export const LOG_OUT_TEXT = 'Log Out';

const NavigationBarMenu = ({ menuVisible, anchorEl, handleClickAway }) => {
  const history = useHistory();

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove(process.env.REACT_APP_AUTH_COOKIE_NAME, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    localStorage.clear();
    history.push({
      pathname: '/',
    });
  };

  return (
    <Menu
      open={menuVisible}
      anchorEl={anchorEl}
      onClose={handleClickAway}
      onClick={handleClickAway}
    >
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        {LOG_OUT_TEXT}
      </MenuItem>
    </Menu>
  );
};

NavigationBarMenu.propTypes = {
  menuVisible: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object.isRequired,
  handleClickAway: PropTypes.func.isRequired,
};

export default NavigationBarMenu;
