import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  InputLabel,
  MenuItem,
  TextField,
  FormControl,
} from '@material-ui/core';
import { useStore } from 'src/context/StoreContext';

const USER_SELECTION_FORM = 'USER_SELECTION_FORM';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;
`;

const DropDown = styled(TextField)`
  width: 100%;
`;

const Label = styled(InputLabel)`
  width: 100%;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
`;

const DROPDOWN_LABEL = 'Assign To: (optional)';
const DROPDOWN_PLACEHOLDER = 'Choose Staff Member';
const UNASSIGNED_LABEL = 'Unassigned';

const CreateEditTaskAssignUserDropdown = ({
  assignedUser,
  setAssignedUsers,
  disabled,
}) => {
  const store = useStore();
  const { usersAsArray } = store.userStore;
  return (
    <Container>
      <Label>{DROPDOWN_LABEL} </Label>
      <FormControl data-testid={USER_SELECTION_FORM} style={{ width: '100%' }}>
        <DropDown
          select
          label={!assignedUser ? DROPDOWN_PLACEHOLDER : ''}
          value={assignedUser || ''}
          onChange={(e) => setAssignedUsers(e.target.value)}
          variant="outlined"
          InputLabelProps={{
            shrink: false,
            style: {
              height: '47px',
              top: `-4px`,
              fontSize: '14px',
            },
          }}
          InputProps={{
            style: {
              height: '47px',
              fontSize: '14px',
              backgroundColor: disabled ? '#e9e9e9' : '#ffffff',
            },
          }}
          disabled={disabled}
        >
          {assignedUser ? (
            <MenuItem value="">
              <em>{UNASSIGNED_LABEL}</em>
            </MenuItem>
          ) : null}
          {usersAsArray.map((user) => (
            <MenuItem key={user.id} value={user}>
              {`${user.firstName} ${user.lastName}`}
            </MenuItem>
          ))}
        </DropDown>
      </FormControl>
    </Container>
  );
};

CreateEditTaskAssignUserDropdown.propTypes = {
  setAssignedUsers: PropTypes.func,
  assignedUser: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CreateEditTaskAssignUserDropdown;
