/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Container = styled('div')`
  border-radius: ${({ size }) => size || 20}px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.palette.red.main};
  width: ${({ size }) => size || 20}px;
  height: ${({ size }) => size || 20}px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isVisible }) => !isVisible && 'display: none'}
`;

const Text = styled(Typography)`
  color: ${({ theme, textColor }) =>
    textColor || theme.palette.red.contrastText};
  font-weight: bold;
`;

const CountCircle = ({
  count,
  isVisible = true,
  size,
  backgroundColor,
  textColor,
  variant,
  ...props
} = {}) => {
  return (
    <Container
      size={size}
      isVisible={isVisible}
      backgroundColor={backgroundColor}
      {...props}
    >
      <Text variant={variant || 'body1'} textColor={textColor}>
        {count}
      </Text>
    </Container>
  );
};

CountCircle.propTypes = {
  count: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  size: PropTypes.number,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  variant: PropTypes.string,
};

export default CountCircle;
