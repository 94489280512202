import React from 'react';
import styled from '@emotion/styled';

import BottomNavigationBar from 'src/components/BottomNavigationBar/BottomNavigationBar';
import RoomScreen from 'src/screens/Dashboard/Rooms/RoomScreen/RoomScreen';
import NavigationBar from 'src/components/NavigationBar/NavigationBar';

const Container = styled('div')`
  display: grid;
  height: 100vh;
  grid-template-rows: 75px 50px 1fr auto;
  grid-template-areas:
    'header'
    'rooms_filters'
    'rooms_list'
    'navbar';
`;

const DashboardMobile = () => {
  return (
    <Container>
      <NavigationBar />
      <RoomScreen />
      <BottomNavigationBar />
    </Container>
  );
};

export default DashboardMobile;
