import { types, flow } from 'mobx-state-tree';
import { values } from 'mobx';
import { request } from '../utils/LodgebookAPIClient';

export const USERS_URL = '/users';

export const User = types.model('User', {
  id: types.identifierNumber,
  username: types.string,
  firstName: types.string,
  lastName: types.string,
  isAuthenticatedUser: types.boolean,
  userType: types.maybeNull(types.string),
  languagePreference: types.maybeNull(types.string),
});

export const LANGUAGE_PREFERENCE = {
  ENGLISH: 'english',
  SPANISH: 'spanish',
  CHINESE: 'chinese',
};

const UserStore = types
  .model('UserStore', {
    users: types.optional(types.map(User), {}),
    isFetchingAll: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
  })
  .views((self) => ({
    get usersAsArray() {
      return values(self.users);
    },
    get authenticatedUser() {
      return self.usersAsArray.find((user) => user.isAuthenticatedUser);
    },
  }))
  .actions((self) => {
    const fetchAllUsers = flow(function*(hotelId) {
      self.isFetchingAll = true;
      try {
        const usersResponse = yield request(
          `${USERS_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        self.users = {};
        usersResponse.users.forEach((user) => {
          self.users.set(user.id, user);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch users', error);
      }
      self.isFetchingAll = false;
    });

    const createUser = flow(function*({
      username,
      firstName,
      lastName,
      password,
      hotelId,
      languagePreference,
    }) {
      yield request(USERS_URL, 'POST', {
        body: {
          user: {
            username,
            firstName,
            lastName,
            password,
            hotelId,
            languagePreference,
          },
        },
      });

      yield self.fetchAllUsers(hotelId);
    });

    const dismissNetworkError = () => {
      self.networkError = undefined;
    };

    return {
      fetchAllUsers,
      createUser,
      dismissNetworkError,
    };
  });

export default UserStore;
