import { camelCase, snakeCase } from 'lodash';
import Cookies from 'universal-cookie';

const apiHost = process.env.REACT_APP_API_HOST;

const fullUrl = (url) => `${apiHost}${url}`;

const transformKeys = (obj, transform) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => transformKeys(v, transform));
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [transform(key)]: transformKeys(obj[key], transform),
      }),
      {}
    );
  }
  return obj;
};

const snakeifyKeys = (obj) => {
  return transformKeys(obj, snakeCase);
};

const camelizeKeys = (obj) => {
  return transformKeys(obj, camelCase);
};

const httpJSONRequestOptions = (method, body) => {
  if (body) {
    return {
      method,
      body: JSON.stringify(snakeifyKeys(body)),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    };
  }
  return { method, credentials: 'include' };
};

const defaultOptions = {
  parseResponse: true,
  body: null,
};
/*  Given a Lodgebook API URL, an HTTP request method, and an `options` object, sends an HTTP request to the Lodgebook API via the Web Fetch API.
`options`:
- body: JSON - HTTP Request body - default null
- parseResponse: Boolean - When true, will attempt to convert response to JSON and return that. Default: true

Return:
- JSON Body - When fetch successfully returns with an HTTP status code of 200-299
- null - when `parseResponse` is false, or undefined in the options.

Thrown Errors:
- Web Fetch API Response~ - When fetch returns with an HTTP status code of 300-500
- String - When fetch throws an error (i.e. NetworkError), or encounter an error trying to parse the json of the response.

~See https://developer.mozilla.org/en-US/docs/Web/API/Response for Fetch Response schema.
*/
// eslint-disable-next-line consistent-return
export const request = async (url, method, options = {}) => {
  const optionsWithDefaults = { ...defaultOptions };
  Object.assign(optionsWithDefaults, options);
  try {
    const response = await fetch(
      fullUrl(url),
      httpJSONRequestOptions(method, optionsWithDefaults.body)
    );
    if (!response.ok) {
      throw response;
    }
    try {
      return optionsWithDefaults.parseResponse
        ? camelizeKeys(await response.json())
        : null;
    } catch (jsonParsingError) {
      console.warn(
        `${method} request to ${fullUrl(url)} did not return JSON response`
      );
      throw jsonParsingError;
    }
  } catch (error) {
    const whiteListUrls = new Set(['/users/sign_in']);

    // log the user out if the error is a 401
    if (error.status === 401 && !whiteListUrls.has(url)) {
      const cookies = new Cookies();
      cookies.remove(process.env.REACT_APP_AUTH_COOKIE_NAME, {
        path: '/',
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      localStorage.clear();
      window.location.href = '/';
    } else {
      console.warn(
        `${method} request to ${fullUrl(
          url
        )} resulted in error with status text: ${error}`
      );
      throw error;
    }
  }
};

export const uploadFile = async (url, file, fileKey, method) => {
  const formData = new FormData();
  formData.append(fileKey, file);
  const response = await fetch(fullUrl(url), {
    method,
    body: formData,
    credentials: 'include',
  });
  if (!response.ok) {
    throw response;
  }
  return response;
};

export const uploadSPHPDF = async (pdf, hotelId) => {
  await uploadFile(`/hotels/${hotelId}/sync_sph_pdf`, pdf, 'pdf_file', 'PUT');
};
