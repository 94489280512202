import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import CountCircle from 'src/components/CountCircle/CountCircle';

const COUNT = 'COUNT';

const TaskCountContainer = styled('div')`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  top: 0px;
  z-index: 100;
`;

const Header = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 97%;
`;

const RoomDetailsTaskListHeader = ({ title, taskCountColor, tasks }) => {
  return (
    <Header>
      <TaskCountContainer>
        <CountCircle
          data-testid={COUNT}
          backgroundColor={taskCountColor || '#000000'}
          count={tasks.length}
          variant="subtitle1"
          size={24}
          style={{
            marginRight: '8px',
          }}
        />
        <Typography variant="h6">{title}</Typography>
      </TaskCountContainer>
    </Header>
  );
};

RoomDetailsTaskListHeader.propTypes = {
  tasks: PropTypes.array,
  title: PropTypes.string,
  taskCountColor: PropTypes.string,
};

export default RoomDetailsTaskListHeader;
