import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';

const DISMISS_BUTTON = 'DISMISS_BUTTON';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled('div')`
  position: relative;
  animation: ${fadeInAnimation} 0.225s cubic-bezier(0.4, 0, 0.2, 1) 1;
`;

const Snackbar = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
  background-color: rgb(49, 49, 49);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  min-height: 36px;
  min-width: 375px;
  max-width: 375px;
  letter-spacing: 0.14994px;
  line-height: 20.02px;
  padding: 8px 16px;
`;

const TextContainer = styled('div')`
  color: #ffffff;
  flex: 4;
`;

const ButtonContainer = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled('span')`
  color: lightseagreen;
  text-transform: uppercase;
  font-weight: 700;
`;
const DismissButton = styled(ButtonBase)`
  background-color: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border-color: #000000;
  border-width: 2px;
  border-style: solid;
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const NotificationCard = ({ text, onClose, onRetry }) => {
  return (
    <Container>
      <Snackbar>
        <TextContainer>{text}</TextContainer>
        <ButtonContainer>
          <ButtonBase onClick={onRetry}>
            <ButtonText>Retry</ButtonText>
          </ButtonBase>
        </ButtonContainer>
      </Snackbar>
      <DismissButton data-testid={DISMISS_BUTTON} onClick={onClose}>
        <CloseIcon fill="#000000" width={10} height={10} />
      </DismissButton>
    </Container>
  );
};

NotificationCard.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default NotificationCard;
