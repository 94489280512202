import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import TaskCard from 'src/screens/Dashboard/Tasks/TaskCard/TaskCard';
import { INCOMPLETE_STATUS, COMPLETE_STATUS } from 'src/models/TaskStore';
import TaskDetailsCard from '../../../Tasks/TaskCard/TaskDetailsCard';
import RoomDetailsTaskListHeader from './RoomDetailsTaskListHeader';
import RoomDetailsEmptyState from './RoomDetailsEmptyState';

const Container = styled('div')`
  width: 100%;
  position: relative;
`;

const TasksContainer = styled('div')`
  width: 100%;
  padding-left: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-height: 400px;
    overflow-y: scroll;
  }
  > * {
    margin-bottom: 10px;
  }
`;

const RoomDetailsTaskList = ({
  testId,
  tasks,
  title,
  taskCountColor,
  taskStatus,
  setEditTaskOpen,
  setTaskToEdit,
  isEmptyList,
  setTaskHistoryOpen,
  taskHistoryOpen,
}) => {
  const { taskId } = useParams();
  const [selectedTask, setSelectedTask] = useState(Number(taskId));

  const taskList = tasks.map((task) => {
    if (selectedTask === task.id) {
      return (
        <TaskDetailsCard
          setTaskToEdit={setTaskToEdit}
          setEditTaskOpen={setEditTaskOpen}
          key={task.id}
          task={task}
          setSelectedTask={setSelectedTask}
        />
      );
    }
    return (
      <TaskCard key={task.id} task={task} setSelectedTask={setSelectedTask} />
    );
  });

  return (
    <Container data-testid={testId}>
      {tasks.length > 0 || (taskStatus === COMPLETE_STATUS && !isEmptyList) ? (
        <RoomDetailsTaskListHeader
          setTaskHistoryOpen={setTaskHistoryOpen}
          taskHistoryOpen={taskHistoryOpen}
          taskStatus={taskStatus}
          tasks={tasks}
          title={title}
          taskCountColor={taskCountColor}
        />
      ) : null}
      <TasksContainer>
        {tasks.length === 0 && taskStatus === INCOMPLETE_STATUS ? (
          <RoomDetailsEmptyState
            isEmptyList={isEmptyList}
            setTaskHistoryOpen={setTaskHistoryOpen}
            taskHistoryOpen={taskHistoryOpen}
          />
        ) : (
          taskList
        )}
      </TasksContainer>
    </Container>
  );
};

RoomDetailsTaskList.propTypes = {
  tasks: PropTypes.array,
  title: PropTypes.string,
  testId: PropTypes.string,
  taskCountColor: PropTypes.string,
  taskStatus: PropTypes.string,
  setEditTaskOpen: PropTypes.func,
  setTaskToEdit: PropTypes.func,
  isEmptyList: PropTypes.bool,
  setTaskHistoryOpen: PropTypes.func,
  taskHistoryOpen: PropTypes.bool,
};

export default RoomDetailsTaskList;
