import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`;

const Label = styled(InputLabel)`
  width: 100%;
  margin-bottom: 10px;
  color: #000000;
`;
const ErrorClass = styled('span')`
  color: red;
  font-size: 12px;
  text-overflow: ellipsis;
  width: 100%;
  max-height: 36px;
  overflow: hidden;
`;

const FormInput = ({
  label,
  placeholder,
  formValue,
  handleChange,
  disabled,
  testId,
  valid,
  errorMessage,
  isTextArea,
}) => {
  const clearInputButton = (
    <InputAdornment position="end">
      <IconButton
        aria-label="clear text field"
        onClick={() => handleChange('')}
        edge="end"
      >
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <Container>
      <Label>{label} </Label>
      <FormControl style={{ width: '100%' }}>
        {isTextArea ? (
          <OutlinedInput
            multiline
            minRows={2}
            maxRows={2}
            data-testid={testId}
            placeholder={placeholder}
            value={formValue}
            onChange={(e) => handleChange(e.target.value)}
            disabled={disabled}
            style={{
              backgroundColor: disabled ? '#e9e9e9' : '#ffffff',
            }}
            endAdornment={!disabled && formValue.length > 0 && clearInputButton}
          />
        ) : (
          <OutlinedInput
            data-testid={testId}
            placeholder={placeholder}
            value={formValue}
            onChange={(e) => handleChange(e.target.value)}
            disabled={disabled}
            style={{
              backgroundColor: disabled ? '#e9e9e9' : '#ffffff',
            }}
            endAdornment={!disabled && formValue.length > 0 && clearInputButton}
          />
        )}
        <ErrorClass
          style={{ visibility: errorMessage && !valid ? 'visible' : 'hidden' }}
        >
          {errorMessage}
        </ErrorClass>
      </FormControl>
    </Container>
  );
};

FormInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  formValue: PropTypes.string,
  testId: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  isTextArea: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default FormInput;
