import { useMediaQuery, useTheme } from '@material-ui/core';

export default () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumOrSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return { isSmallScreen, isMediumOrSmallerScreen, isLargeScreen };
};
