import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import NoTasks from 'src/assets/icons/no_tasks.svg';

const NO_OPEN_TASKS_TEXT = 'No Open Tasks';
const EMPTY_IMAGE = 'EMPTY_IMAGE';

const Container = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

const EmptyImageText = styled(Typography)`
  color: #666666;
  margin-bottom: 40px;
`;

const RoomDetailsEmptyState = () => {
  return (
    <Container>
      <EmptyImageText variant="h6"> {NO_OPEN_TASKS_TEXT} </EmptyImageText>
      <img
        data-testid={EMPTY_IMAGE}
        alt="There are no tasks"
        style={{ width: '100px' }}
        src={NoTasks}
      />
    </Container>
  );
};

export default RoomDetailsEmptyState;
