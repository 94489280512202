import React from 'react';
import styled from '@emotion/styled';

import BottomNavigationBar from 'src/components/BottomNavigationBar/BottomNavigationBar';
import GuestMessageScreen from 'src/screens/Dashboard/GuestMessages/GuestMessageScreen';

const Container = styled('div')`
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'messages'
    'navbar';
`;

const GuestMessageScreenMobile = () => {
  return (
    <Container>
      <GuestMessageScreen />
      <BottomNavigationBar />
    </Container>
  );
};

export default GuestMessageScreenMobile;
