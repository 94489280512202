import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { MenuItem, Select, FormControl } from '@material-ui/core';

const SORT_SELECTION_FORM = 'SORT_SELECTION_FORM';
const ROOM_SORT_LABEL = 'Room';
const DESCRIPTION_SORT_LABEL = 'Description';
const RECENT_SORT_LABEL = 'Recent';
const ROOM_SORT_VALUE = 'room';
const DESCRIPTION_SORT_VALUE = 'description';
const RECENT_SORT_VALUE = 'recent';

const DropDown = styled(Select)`
  width: 125px;
  margin-top: 3px;
  margin-left: 10px;
  ${({ theme }) => theme.typography.subtitle2}
`;

const TaskSortDropdown = ({ setSortOption, sortOption }) => {
  return (
    <FormControl variant="standard">
      <DropDown
        value={sortOption}
        onChange={(e) => setSortOption(e.target.value)}
        data-testid={SORT_SELECTION_FORM}
      >
        <MenuItem key={1} value={ROOM_SORT_VALUE}>
          {ROOM_SORT_LABEL}
        </MenuItem>
        <MenuItem key={2} value={DESCRIPTION_SORT_VALUE}>
          {DESCRIPTION_SORT_LABEL}
        </MenuItem>
        <MenuItem key={3} value={RECENT_SORT_VALUE}>
          {RECENT_SORT_LABEL}
        </MenuItem>
      </DropDown>
    </FormControl>
  );
};

TaskSortDropdown.propTypes = {
  setSortOption: PropTypes.func,
  sortOption: PropTypes.string,
};

export default TaskSortDropdown;
