import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { ButtonBase, CircularProgress, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStore } from 'src/context/StoreContext';
import {
  BLOCK_ROOM,
  MARK_AS_CLEAN,
  MARK_AS_DIRTY,
  UNBLOCK_CLEAN_ROOM,
  UNBLOCK_DIRTY_ROOM,
  TURN_ON_DO_NOT_DISTURB,
  TURN_OFF_DO_NOT_DISTURB,
  MARK_AS_VACANT,
  MARK_AS_STAYOVER,
} from './RoomDetailStrings';

const MenuItemButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  padding: 25px;
  opacity: ${({ disabled }) => (disabled ? 0.35 : 1)};
  transition: 0.3s;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 75px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  ${({ theme }) => theme.typography.body1}
`;

const IconContainer = styled('div')`
  width: iconWidth;
  height: iconHeight;
  padding-right: 15px;
`;

const TitleIcon = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const SelectedIndicator = styled('div')`
  position: absolute;
  height: 100%;
  width: 10px;
  top: 0px;
  right: 0px;
  background-color: ${(props) => props.selectedColor || 'clear'};
`;

const RoomDetailsMenuItem = observer(
  ({ title, Icon, onClick, disabled, selectedColor }) => {
    const { roomStore } = useStore();
    const iconWidth = 28;
    const iconHeight = 28;
    let isUpdatingStatus = null;

    if (
      title === MARK_AS_DIRTY ||
      title === MARK_AS_CLEAN ||
      title === UNBLOCK_CLEAN_ROOM
    ) {
      isUpdatingStatus = roomStore.isUpdatingFirstMenuItem;
    } else if (title === BLOCK_ROOM || title === UNBLOCK_DIRTY_ROOM) {
      isUpdatingStatus = roomStore.isUpdatingSecondMenuItem;
    } else if (
      title === TURN_ON_DO_NOT_DISTURB ||
      title === TURN_OFF_DO_NOT_DISTURB
    ) {
      isUpdatingStatus = roomStore.isUpdatingThirdMenuItem;
    } else if (title === MARK_AS_STAYOVER || title === MARK_AS_VACANT) {
      isUpdatingStatus = roomStore.isUpdatingFourthMenuItem;
    }

    return (
      <MenuItemButton onClick={onClick} disabled={disabled}>
        <TitleIcon>
          {Icon ? (
            <IconContainer>
              <Icon fill="#666666" width={iconWidth} height={iconHeight} />
            </IconContainer>
          ) : null}
          <Typography variant="body1">{title}</Typography>
        </TitleIcon>
        <div>
          {isUpdatingStatus ? (
            <CircularProgress data-testid="ROOM_DETAILS_ITEM_STATUS_SPINNER" />
          ) : null}
        </div>
        <SelectedIndicator selectedColor={selectedColor} />
      </MenuItemButton>
    );
  }
);

RoomDetailsMenuItem.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selectedColor: PropTypes.string,
};

export default RoomDetailsMenuItem;
