import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

import { useStore } from 'src/context/StoreContext';
import NotificationCard from './NotificationCard';

const MORE_THAN_THREE_NOTIFICATIONS_MESSAGE_PREFIX = 'Unable to update';
const MORE_THAN_THREE_NOTIFICATIONS_MESSAGE_SUFFIX = 'rooms, please try again.';

const Container = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  z-index: 9999;

  > * {
    pointer-events: auto;
  }
`;

const NotificationOverlay = observer(() => {
  const { notificationStore } = useStore();

  const getNotificationCards = () => {
    const { notificationsAsArray } = notificationStore;

    if (notificationsAsArray.length >= 3) {
      return (
        <NotificationCard
          text={`${MORE_THAN_THREE_NOTIFICATIONS_MESSAGE_PREFIX} ${notificationsAsArray.length} ${MORE_THAN_THREE_NOTIFICATIONS_MESSAGE_SUFFIX}`}
          onRetry={() => {
            notificationsAsArray.forEach(({ id, callback }) => {
              callback();
              notificationStore.deleteNotification({ id });
            });
          }}
          onClose={() => {
            notificationsAsArray.forEach(({ id }) => {
              notificationStore.deleteNotification({ id });
            });
          }}
        />
      );
    }

    return notificationsAsArray.map(({ id, text, callback }) => {
      return (
        <NotificationCard
          key={id}
          text={text}
          onRetry={() => {
            callback();
            notificationStore.deleteNotification({ id });
          }}
          onClose={() => {
            notificationStore.deleteNotification({ id });
          }}
        />
      );
    });
  };

  return <Container>{getNotificationCards()}</Container>;
});

export default NotificationOverlay;
