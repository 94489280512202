/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from '@emotion/styled';
import { Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useRoomFilters } from './RoomFiltersContext';

const RoomCount = styled(Typography)`
  font-weight: bold;
`;
RoomCount.defaultProps = {
  variant: 'body2',
};

const Container = styled(Button)`
  ${({ theme }) => theme.typography.subtitle2}
  text-transform: capitalize;
  margin-right: 10px;
  min-width: 100px;
  max-height: 33px;
`;

const RoomFilterStatusButton = ({
  title,
  color = 'grey',
  filterType,
  ...props
} = {}) => {
  const {
    filters,
    toggleFilter,
    dynamicFilterCounts,
    isFilterDisabled,
  } = useRoomFilters();

  return (
    <Container
      onClick={() => toggleFilter(filterType)}
      variant={filters[filterType] ? 'contained' : 'outlined'}
      color={color}
      disabled={isFilterDisabled(filterType)}
      {...props}
    >
      <RoomCount>{dynamicFilterCounts[filterType]}</RoomCount>
      &nbsp;
      {title}
    </Container>
  );
};

RoomFilterStatusButton.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  filterType: PropTypes.string.isRequired,
};

export default RoomFilterStatusButton;
