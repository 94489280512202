import React from 'react';

const StoreContext = React.createContext(null);

// https://mobx-react.js.org/recipes-context
export const useStore = () => {
  const store = React.useContext(StoreContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreContext.Provider.');
  }
  return store;
};
export default StoreContext;
