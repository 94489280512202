import React from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { TEXT_MESSAGE_TYPES } from 'src/models/TextMessageStore';

const TextMessageContainer = styled('div')`
  display: flex;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  background-color: ${(props) => props.backgroundColor};
  max-width: 70%;
`;

const GuestMessages = ({ textMessages, timeZone }) => {
  const textMessagesSorted = [...textMessages].sort(
    (a, b) => new Date(a.sentAt) - new Date(b.sentAt)
  );

  const messages = textMessagesSorted.map((textMessage) => {
    const marginLeft =
      textMessage.type === TEXT_MESSAGE_TYPES.OUTBOUND_TEXT_MESSAGE
        ? 'auto'
        : '15px';
    const marginRight =
      textMessage.type === TEXT_MESSAGE_TYPES.INBOUND_TEXT_MESSAGE
        ? 'auto'
        : '15px';
    const backgroundColor =
      textMessage.type === TEXT_MESSAGE_TYPES.OUTBOUND_TEXT_MESSAGE
        ? '#ebebeb'
        : '#FFFFFF';
    const timestamp = moment(textMessage.sentAt)
      .tz(timeZone)
      .format('MMM Do, h:mma');

    return (
      <React.Fragment key={textMessage.id}>
        <TextMessageContainer
          backgroundColor={backgroundColor}
          marginLeft={marginLeft}
          marginRight={marginRight}
        >
          <Typography variant="body2">{textMessage.content}</Typography>
        </TextMessageContainer>
        <Typography
          variant="caption"
          style={{
            color: '#666666',
            marginLeft,
            marginRight,
            marginTop: '5px',
          }}
        >
          {timestamp}
        </Typography>
      </React.Fragment>
    );
  });
  return messages;
};

GuestMessages.propTypes = {
  textMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      sentAt: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      guestId: PropTypes.number.isRequired,
      guest: PropTypes.shape({
        id: PropTypes.number,
        roomId: PropTypes.number,
      }),
    })
  ),
  timeZone: PropTypes.string,
};

export default GuestMessages;
