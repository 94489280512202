import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';

import { useStore } from 'src/context/StoreContext';
import { INCOMPLETE_STATUS } from 'src/models/TaskStore';
import TaskCardsByRoom from './TaskCardsByRoom';
import TaskCardsByDescription from './TaskCardsByDescription';

const OPEN_TASKS_EMPTY_STATE_TEXT = 'No Open Tasks';
const COMPLETE_TASKS_EMPTY_STATE_TEXT = 'No Complete Tasks';
const GET_TASKS_FAILURE_TEXT = 'Tasks Failed to Display';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 100%;
  overflow-y: scroll;
  background-color: #ececec;
  border-top: 2px solid #20b2aa;
  padding-top: 15px;
`;

const NoTasksContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666666;
`;

const TaskList = observer(
  ({
    selectedTaskStatus,
    taskCount,
    networkError,
    isFetchingAll,
    sortOption,
  }) => {
    const { roomsAsArray } = useStore().roomStore;
    const { tasksAsArray, completedTodayTasks } = useStore().taskStore;
    const { authenticatedUser } = useStore().userStore;

    const emptyStateLabel =
      selectedTaskStatus === INCOMPLETE_STATUS
        ? OPEN_TASKS_EMPTY_STATE_TEXT
        : COMPLETE_TASKS_EMPTY_STATE_TEXT;

    let taskListContent;

    if (networkError) {
      taskListContent = (
        <NoTasksContainer>
          <Typography variant="h6">{GET_TASKS_FAILURE_TEXT}</Typography>
        </NoTasksContainer>
      );
    } else if (isFetchingAll) {
      taskListContent = (
        <NoTasksContainer>
          <CircularProgress data-testid="spinner" />
        </NoTasksContainer>
      );
    } else {
      let sections;
      if (sortOption === 'room') {
        sections = (
          <TaskCardsByRoom
            roomsAsArray={roomsAsArray}
            selectedTaskStatus={selectedTaskStatus}
          />
        );
      } else if (sortOption === 'description') {
        sections = (
          <TaskCardsByDescription
            authenticatedUser={authenticatedUser}
            tasksAsArray={tasksAsArray}
            selectedTaskStatus={selectedTaskStatus}
            completedTodayTasks={completedTodayTasks}
          />
        );
      } else if (sortOption === 'recent') {
        sections = (
          <TaskCardsByRoom
            roomsAsArray={roomsAsArray}
            selectedTaskStatus={selectedTaskStatus}
            sortByCreatedAt
          />
        );
      }
      taskListContent = (
        <>
          {sections}
          {taskCount === 0 ? (
            <NoTasksContainer>
              <Typography variant="h6">{emptyStateLabel}</Typography>
            </NoTasksContainer>
          ) : null}
        </>
      );
    }
    return <Container>{taskListContent}</Container>;
  }
);

TaskList.propTypes = {
  selectedTaskStatus: PropTypes.string,
  taskCount: PropTypes.number,
  networkError: PropTypes.object,
  isFetchingAll: PropTypes.bool,
};

export default TaskList;
