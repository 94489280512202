import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@material-ui/core';

import { COMPLETE_STATUS } from 'src/models/TaskStore';

export const CREATED_TEXT = 'Created';
export const ASSIGNED_TEXT = 'Assigned';
export const COMPLETED_TEXT = 'Completed';
export const AGO_TEXT = 'ago';
const createdProperty = 'createdAt';
const completedProperty = 'completedAt';
const assignedProperty = 'assignedAt';

const TextContainer = styled('div')`
  color: #666666;
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
`;

const Text = styled(Typography)`
  color: #666666;
`;
Text.defaultProps = {
  variant: 'caption',
};

const getTimestamp = (type, date) => {
  let description;
  switch (type) {
    case createdProperty:
      description = `${CREATED_TEXT} ${moment
        .duration(moment() - moment(date))
        .humanize()} ago`;
      break;
    case assignedProperty:
      description = `${ASSIGNED_TEXT} ${moment
        .duration(moment() - moment(date))
        .humanize()} ago`;
      break;
    case completedProperty:
      description = `${COMPLETED_TEXT} ${moment
        .duration(moment() - moment(date))
        .humanize()} ago`;
      break;
    default:
      description = null;
  }
  return <Text>{description}</Text>;
};

const TaskDetailsCardText = ({ task, createdByName }) => {
  return (
    <TextContainer>
      {createdByName ? (
        <>
          <Text> {`Created By ${createdByName}`} </Text>
          <Text> &nbsp; | &nbsp; </Text>
        </>
      ) : null}

      {getTimestamp(createdProperty, task.createdAt)}
      {task.taskAssignments.length > 0 ? (
        <>
          <Text> &nbsp; | &nbsp; </Text>
          {getTimestamp(assignedProperty, task.assignedAt)}
        </>
      ) : null}
      {task.status === COMPLETE_STATUS ? (
        <>
          <Text> &nbsp; | &nbsp; </Text>
          {getTimestamp(completedProperty, task.completedAt)}
        </>
      ) : null}
    </TextContainer>
  );
};

TaskDetailsCardText.propTypes = {
  task: PropTypes.object,
  createdByName: PropTypes.string,
};

export default TaskDetailsCardText;
