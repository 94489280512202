import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Typography, CircularProgress, Button } from '@material-ui/core';

import { ReactComponent as LeftIcon } from 'src/assets/icons/left_arrow.svg';
import GuestMessages from './GuestMessages';

export const EXIT_GUEST_MESSAGES = 'EXIT_GUEST_MESSAGES';
export const MESSAGE_FAILURE_TEXT = 'Message failed to send. Please try again.';
export const SEND_MESSAGE_TEXT_AREA = 'SEND_MESSAGE_TEXT_AREA';
export const SEND_MESSAGE_LOADER = 'SEND_MESSAGE_LOADER';

const Container = styled('div')`
  display: grid;
  grid-template-rows: 75px 1fr 150px;
  height: 100%;
`;

const HeaderContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #ebebeb;
  width: 100%;
`;

const BackIcon = styled(LeftIcon)`
  height: 13px;
  width: 13px;
  fill: lightseagreen;
  cursor: pointer;
`;

const BackContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 10px;
  cursor: pointer;
`;

const MainContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
  padding-bottom: 20px;
`;

const SendMessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -2px 2px rgba(50, 50, 50, 0.1);
  padding-bottom: 25px;
`;

const SendMessageTextArea = styled('textarea')`
  width: 85%;
  height: 65%;
  margin-top: 3%;
  margin-bottom: 4%;
`;

const SendButton = styled(Button)`
  width: 90%;
  height: 30%;
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const NetworkFailureText = styled(Typography)`
  color: #e94f62;
  margin-left: auto;
  margin-right: 15px;
  margin-top: 20px;
  margin-bottom: 0px;
  max-width: 50%;
  text-align: right;
`;

const GuestMessageDetails = observer(
  ({
    textMessages,
    setSelectedGuestId,
    rooms,
    timeZone,
    createTextMessage,
    authenticatedUser,
    networkError,
  }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const [contentInput, setContentInput] = useState('');
    const [isCreatingMessage, setIsCreatingMessage] = useState(false);
    const BACK_TEXT = 'Back';
    const SEND_TEXT = 'Send';
    const SEND_PLACEHOLDER = 'Write your message...';
    const { roomId } = textMessages[0].guest;
    const roomNumber = rooms.get(roomId).number;

    useEffect(() => {
      scrollToBottom();

      if (!networkError) {
        setContentInput('');
      }
    }, [textMessages, networkError]);

    const onSubmit = async (e) => {
      setIsCreatingMessage(true);
      e.preventDefault();

      const payload = {
        content: contentInput,
        guestId: textMessages[0].guest.id,
        userId: authenticatedUser.id,
      };

      await createTextMessage({
        body: {
          textMessage: {
            ...payload,
          },
        },
      });

      setIsCreatingMessage(false);
    };

    return (
      <Container>
        <HeaderContainer>
          <BackContainer onClick={() => setSelectedGuestId(null)}>
            <BackIcon data-testid={EXIT_GUEST_MESSAGES} />
            <Typography
              style={{ color: 'lightseagreen', marginLeft: '10px' }}
              variant="body2"
            >
              {BACK_TEXT}
            </Typography>
          </BackContainer>
          <Typography variant="h6">{`Room ${roomNumber}`}</Typography>
        </HeaderContainer>
        <MainContentContainer>
          <GuestMessages textMessages={textMessages} timeZone={timeZone} />
          {networkError ? (
            <NetworkFailureText variant="body2">
              {MESSAGE_FAILURE_TEXT}
            </NetworkFailureText>
          ) : null}
          <div ref={messagesEndRef} />
        </MainContentContainer>
        <SendMessageContainer>
          <SendMessageTextArea
            autoFocus
            onChange={(e) => setContentInput(e.target.value)}
            value={contentInput}
            placeholder={SEND_PLACEHOLDER}
            data-testid={SEND_MESSAGE_TEXT_AREA}
          />
          <SendButton
            variant="contained"
            disabled={!!(isCreatingMessage || contentInput.length === 0)}
            onClick={(e) => onSubmit(e)}
          >
            {isCreatingMessage ? (
              <CircularProgress
                size={20}
                color="inherit"
                data-testid={SEND_MESSAGE_LOADER}
              />
            ) : (
              SEND_TEXT
            )}
          </SendButton>
        </SendMessageContainer>
      </Container>
    );
  }
);

GuestMessageDetails.propTypes = {
  textMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      sentAt: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      guestId: PropTypes.number.isRequired,
      guest: PropTypes.shape({
        id: PropTypes.number,
        roomId: PropTypes.number,
      }),
    })
  ),
  timeZone: PropTypes.string,
  rooms: PropTypes.object,
  setSelectedGuestId: PropTypes.func,
  createTextMessage: PropTypes.func,
  authenticatedUser: PropTypes.object,
  networkError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default GuestMessageDetails;
