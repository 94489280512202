import React, { useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';

import { useStore } from 'src/context/StoreContext';
import NavigationBar from 'src/components/NavigationBar/NavigationBar';
import TaskPanel from './Tasks/TaskScreen/TaskScreen';
import CreateTaskModal from './Tasks/CreateEditTaskScreen/CreateTaskModal';
import RoomScreen from './Rooms/RoomScreen/RoomScreen';
import GuestMessageScreen from './GuestMessages/GuestMessageScreen';

const Container = styled('div')`
  display: grid;
  height: 100vh;
  grid-template-columns: minmax(300px, 350px) 1fr;
  grid-template-rows: 65px minmax(50px, 65px) 1fr;
  grid-template-areas:
    'navbar       navbar'
    'hotel_info   rooms_filters'
    'tasks_panel  rooms_list';
  padding: 20px;
  grid-gap: 20px;
`;

const HotelInfo = styled(Typography)`
  grid-area: hotel_info;
  color: #666666;
  font-weight: bold;
  text-align: center;
  align-self: center;
`;

const Dashboard = observer(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const hotelName = useStore().hotelStore.name;

  return (
    <Container>
      <NavigationBar />
      <HotelInfo variant="h5">{hotelName}</HotelInfo>
      <TaskPanel setIsModalOpen={setIsModalOpen} />
      <RoomScreen
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <CreateTaskModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <GuestMessageScreen
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </Container>
  );
});

export default Dashboard;
