import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { CircularProgress, Button } from '@material-ui/core';

import {
  CANCEL_BUTTON_TEXT,
  CANCEL_BUTTON,
  CREATE_NEW_TASK_TEXT,
  EDIT_BUTTON_TEXT,
  SUBMIT_BUTTON,
  SUBMIT_BUTTON_LOADING_SPINNER,
  CREATE_BLOCK_TEXT,
} from './CreateEditTaskStrings';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const CancelButton = styled(Button)`
  height: 40px;
  flex: 4;
`;

const SubmitButton = styled(Button)`
  height: 40px;
  flex: 6;
  margin-left: 10px;
`;

const CreateEditTaskFormFooter = ({
  handleGoBack,
  roomInputFromModal,
  roomIdFromRoomDetails,
  taskDescription,
  task,
  isLoading,
  isRoomBlock,
  valid,
}) => {
  let submitButtonContent;
  if (isLoading) {
    submitButtonContent = (
      <CircularProgress
        data-testid={SUBMIT_BUTTON_LOADING_SPINNER}
        size={25}
        color="inherit"
      />
    );
  } else if (isRoomBlock) {
    submitButtonContent = CREATE_BLOCK_TEXT;
  } else {
    submitButtonContent = task ? EDIT_BUTTON_TEXT : CREATE_NEW_TASK_TEXT;
  }

  return (
    <Container>
      <CancelButton
        color="grey"
        variant="text"
        data-testid={CANCEL_BUTTON}
        onClick={handleGoBack}
      >
        {CANCEL_BUTTON_TEXT}
      </CancelButton>
      <SubmitButton
        variant="contained"
        data-testid={SUBMIT_BUTTON}
        disabled={
          (!roomInputFromModal && !roomIdFromRoomDetails) ||
          !taskDescription ||
          !valid
        }
        type="submit"
      >
        {submitButtonContent}
      </SubmitButton>
    </Container>
  );
};

CreateEditTaskFormFooter.propTypes = {
  task: PropTypes.object,
  handleGoBack: PropTypes.func,
  isLoading: PropTypes.bool,
  roomIdFromRoomDetails: PropTypes.number,
  taskDescription: PropTypes.string,
  isRoomBlock: PropTypes.bool,
  roomInputFromModal: PropTypes.string,
  valid: PropTypes.bool,
};

export default CreateEditTaskFormFooter;
