import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

import { INCOMPLETE_STATUS } from 'src/models/TaskStore';
import TaskCard from 'src/screens/Dashboard/Tasks/TaskCard/TaskCard';

const TaskCardContainer = styled('div')`
  width: 90%;
  border-radius: 4px;
  margin-bottom: 15px;
`;

const ROOM_SECTION_HEADER = 'ROOM_SECTION_HEADER';

const TaskCardsByRoom = ({
  selectedTaskStatus,
  roomsAsArray,
  sortByCreatedAt,
}) => {
  const tasksForRoom = roomsAsArray
    .map((room) => {
      let tasks;
      if (selectedTaskStatus === INCOMPLETE_STATUS) {
        tasks = room.incompleteTasks;
      } else {
        tasks = room.completedTodayTasks;
      }

      if (tasks.length === 0) {
        return null;
      }

      tasks.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      const mostRecentCreatedAt = new Date(
        Math.max(...tasks.map((task) => new Date(task.createdAt)))
      );

      return {
        room: {
          id: room.id,
          number: room.number,
          mostRecentCreatedAt,
        },
        tasks,
      };
    })
    .filter((el) => el);

  if (sortByCreatedAt) {
    tasksForRoom.sort((a, b) => {
      return (
        new Date(b.room.mostRecentCreatedAt) -
        new Date(a.room.mostRecentCreatedAt)
      );
    });
  }

  const roomSections = tasksForRoom.map((section) => {
    return (
      <React.Fragment key={section.room.id}>
        <div style={{ width: '90%', color: '#666666' }}>
          <Typography
            data-testid={ROOM_SECTION_HEADER}
            style={{ margin: '10px 0px' }}
            variant="h6"
          >
            Room {section.room.number}
          </Typography>
        </div>
        {section.tasks.map((task) => (
          <TaskCardContainer key={task.id}>
            <TaskCard task={task} />
          </TaskCardContainer>
        ))}
      </React.Fragment>
    );
  });

  return roomSections;
};

TaskCardsByRoom.propTypes = {
  selectedTaskStatus: PropTypes.string,
  roomsAsArray: PropTypes.array,
  sortByCreatedAt: PropTypes.bool,
};

export default TaskCardsByRoom;
