import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { useStore } from 'src/context/StoreContext';
import { USER_LOGIN_URL } from 'src/api/Routes';
import { request } from 'src/utils/LodgebookAPIClient';
import { ReactComponent as LodgebookLogo } from 'src/assets/images/lodgebook_logo.svg';

export const LOGIN_NETWORK_FAILURE_TEXT = 'Something went wrong.';
export const LOGIN_CREDENTIALS_FAILURE_TEXT =
  'Your credentials do not match our records';
export const LOGIN_HEADER = 'Lodgebook';

export const dataTestIds = {
  LOGIN_BUTTON: 'LOGIN_BUTTON',
  ERROR_MESSAGE: 'ERROR',
  USERNAME_INPUT: 'USERNAME',
  PASSWORD_INPUT: 'PASSWORD',
};

const FormContainer = styled('form')`
  display: grid;
  grid-template-columns: minmax(0px, 450px);
  grid-template-rows: auto auto 50px 50px;
  min-height: 325px;
  padding: 30px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const LoginContainer = styled('div')`
  display: grid;
  height: 100vh;
  grid-template-rows: 150px minmax(200px, 500px);
  padding: 7.5% 25px;
  justify-items: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const HeaderText = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
`;

const Login = () => {
  const store = useStore();
  const { fetchAndSetHotel } = store.hotelStore;
  const history = useHistory();
  const [passwordInput, setPasswordInput] = useState('');
  const [usernameInput, setUsernameInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorRendered, setErrorRendered] = useState('');

  const handleChangeUsername = (event) => {
    setUsernameInput(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPasswordInput(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await request(USER_LOGIN_URL, 'POST', {
        body: {
          user: {
            username: usernameInput,
            password: passwordInput,
          },
        },
        parseResponse: false,
      });
      fetchAndSetHotel();
      history.push({
        pathname: '/dashboard',
      });
    } catch (error) {
      if (error.status === 401) {
        setErrorRendered(LOGIN_CREDENTIALS_FAILURE_TEXT);
      } else {
        setErrorRendered(LOGIN_NETWORK_FAILURE_TEXT);
      }
      setTimeout(() => setErrorRendered(''), 3000);
    }
    setIsLoading(false);
  };

  return (
    <LoginContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LodgebookLogo width={72} height={72} />
        <HeaderText variant="h4">{LOGIN_HEADER}</HeaderText>
      </div>
      <FormContainer onSubmit={handleSubmit}>
        <TextField
          label="Username"
          onChange={handleChangeUsername}
          margin="normal"
          variant="outlined"
          inputProps={{ 'data-testid': dataTestIds.USERNAME_INPUT }}
        />
        <TextField
          label="Password"
          type="password"
          onChange={handleChangePassword}
          margin="normal"
          variant="outlined"
          inputProps={{ 'data-testid': dataTestIds.PASSWORD_INPUT }}
        />
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          data-testid={dataTestIds.LOGIN_BUTTON}
          loading={isLoading}
        >
          Login
        </LoadingButton>
        {errorRendered && (
          <Typography
            variant="subtitle2"
            data-testid={dataTestIds.ERROR_MESSAGE}
            style={{
              alignSelf: 'center',
              justifySelf: 'center',
            }}
          >
            {errorRendered}
          </Typography>
        )}
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
