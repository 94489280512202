import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

import RoomAssignments from './components/RoomAssignments';

const PrintRoomAssignments = ({
  userStore,
  roomStore,
  hotelStore,
  roomAssignments,
}) => {
  const componentRef = useRef();
  const PRINT_ASSIGNMENTS_TEXT = 'Print Assignments';

  const printComponent = (
    <Tooltip title={PRINT_ASSIGNMENTS_TEXT}>
      <IconButton color="primary" aria-label={PRINT_ASSIGNMENTS_TEXT}>
        <PrintIcon />
      </IconButton>
    </Tooltip>
  );
  return (
    <div>
      <ReactToPrint
        trigger={() => printComponent}
        content={() => componentRef.current}
      />
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <RoomAssignments
            userStore={userStore}
            hotelStore={hotelStore}
            roomStore={roomStore}
            roomAssignments={roomAssignments}
          />
        </div>
      </div>
    </div>
  );
};

PrintRoomAssignments.propTypes = {
  roomAssignments: PropTypes.array,
  userStore: PropTypes.object,
  roomStore: PropTypes.object,
  hotelStore: PropTypes.object,
};

export default PrintRoomAssignments;
