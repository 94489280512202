export const ADDITIONAL_NOTES = 'ADDITIONAL_NOTES';
export const ADDITIONAL_NOTES_LABEL = 'Additional Notes (optional)';
export const ADDITIONAL_NOTES_PLACEHOLDER = 'Add a note';
export const TASK_DESCRIPTION = 'TASK_DESCRIPTION';
export const TASK_DESCRIPTION_LABEL = 'Task Description';
export const ROOM_NUMBER_LABEL = 'Room Number';
export const ROOM_NUMBER_PLACEHOLDER = 'Choose a room to assign this task';
export const CANCEL_BUTTON_TEXT = 'CANCEL';
export const CANCEL_BUTTON = 'CANCEL_BUTTON';
export const CANCEL_BUTTON_LOADING_MODAL = 'CANCEL_BUTTON_LOADING_MODAL';
export const CREATE_NEW_TASK_TEXT = 'CREATE NEW TASK';
export const URGENT_TOGGLE = 'URGENT_TOGGLE';
export const URGENT_LABEL_TEXT = 'Urgent';
export const BLOCK_TOGGLE = 'BLOCK_TOGGLE';
export const BLOCK_LABEL_TEXT = 'Block Room';
export const SEARCH_BAR = 'SEARCH_BAR';
export const EDIT_BUTTON_TEXT = 'UPDATE TASK';
export const SUBMIT_BUTTON = 'SUBMIT_BUTTON';
export const SUBMIT_BUTTON_LOADING_SPINNER = 'SUBMIT_BUTTON_LOADING_SPINNER';
export const NETWORK_CREATE_SUCCESS_TEXT = 'Task Created Successfully';
export const NETWORK_CREATE_FAILURE_TEXT = 'Unable to Create Task';
export const NETWORK_PATCH_SUCCESS_TEXT = 'Task Updated Successfully';
export const NETWORK_PATCH_FAILURE_TEXT = 'Unable to Update Task';
export const RETRY_BUTTON_TEXT = 'RETRY';
export const INVALID_ROOM_NUMBER_TEXT = 'is not a valid room number';
export const CREATE_BLOCK_TEXT = 'BLOCK & CREATE TASK';
