import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useStore } from 'src/context/StoreContext';
import FormInput from 'src/components/FormInput/FormInput';
import CreateEditTaskUrgentSwitch from './CreateEditTaskUrgentSwitch';
import CreateEditTaskBlockRoomSwitch from './CreateEditTaskBlockRoomSwitch';
import CreateEditTaskFormFooter from './CreateEditTaskFormFooter';
import CreateEditTaskAssignUserDropdown from './CreateEditTaskAssignUserDropdown';
import {
  TASK_DESCRIPTION,
  TASK_DESCRIPTION_LABEL,
  ROOM_NUMBER_LABEL,
  ROOM_NUMBER_PLACEHOLDER,
  ADDITIONAL_NOTES,
  ADDITIONAL_NOTES_LABEL,
  ADDITIONAL_NOTES_PLACEHOLDER,
  INVALID_ROOM_NUMBER_TEXT,
} from './CreateEditTaskStrings';

const Container = styled('div')`
  display: grid;
  grid-template-columns: repeat(autofit, 1fr);
  width: 100%;
  height: 100%;
  overflow: none;
`;

const CreateEditTaskForm = ({
  room,
  roomNumber,
  setRoomNumber,
  taskDescription,
  setTaskDescription,
  selectedTask,
  additionalNotes,
  setAdditionalNotes,
  assignedUser,
  setAssignedUsers,
  isUrgent,
  setIsUrgent,
  handleGoBack,
  shouldDisableInput,
  task,
  isLoading,
  isRoomBlock,
  setIsRoomBlock,
  roomNumberParsed,
}) => {
  const { roomStore } = useStore();
  const { roomsAsArray } = roomStore;
  const allRoomNumbers = useMemo(() => roomsAsArray.map((r) => r.number), [
    roomsAsArray,
  ]);

  const listOfInvalidRooms = [];
  roomNumberParsed.forEach((number) => {
    const valid = allRoomNumbers.includes(number);
    if (!valid && number) {
      listOfInvalidRooms.push(number);
    }
  });

  return (
    <Container type="form">
      {!room ? (
        <FormInput
          label={ROOM_NUMBER_LABEL}
          placeholder={ROOM_NUMBER_PLACEHOLDER}
          formValue={roomNumber}
          handleChange={setRoomNumber}
          disabled={shouldDisableInput}
          testId={ROOM_NUMBER_LABEL}
          valid={!roomNumber || listOfInvalidRooms.length === 0}
          errorMessage={`${listOfInvalidRooms.join(
            ', '
          )} ${INVALID_ROOM_NUMBER_TEXT}`}
        />
      ) : null}
      <FormInput
        label={TASK_DESCRIPTION_LABEL}
        formValue={taskDescription}
        testId={TASK_DESCRIPTION}
        handleChange={setTaskDescription}
        disabled={
          shouldDisableInput || (selectedTask && !selectedTask.isCustom)
        }
      />
      <FormInput
        label={ADDITIONAL_NOTES_LABEL}
        placeholder={ADDITIONAL_NOTES_PLACEHOLDER}
        formValue={additionalNotes}
        handleChange={setAdditionalNotes}
        testId={ADDITIONAL_NOTES}
        disabled={shouldDisableInput}
        isTextArea
      />
      <CreateEditTaskAssignUserDropdown
        assignedUser={assignedUser}
        setAssignedUsers={setAssignedUsers}
        disabled={shouldDisableInput}
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <CreateEditTaskUrgentSwitch
          checked={isUrgent}
          handleChange={setIsUrgent}
          disabled={shouldDisableInput}
        />
        <CreateEditTaskBlockRoomSwitch
          checked={isRoomBlock}
          handleChange={setIsRoomBlock}
          disabled={shouldDisableInput}
        />
      </div>

      <CreateEditTaskFormFooter
        isRoomBlock={isRoomBlock}
        taskDescription={taskDescription}
        handleGoBack={handleGoBack}
        roomInputFromModal={roomNumber}
        roomIdFromRoomDetails={room ? room.id : null}
        valid={!roomNumber || listOfInvalidRooms.length === 0}
        task={task}
        isLoading={isLoading}
      />
    </Container>
  );
};

CreateEditTaskForm.propTypes = {
  task: PropTypes.object,
  room: PropTypes.object,
  roomNumber: PropTypes.string,
  setRoomNumber: PropTypes.func,
  taskDescription: PropTypes.string,
  setTaskDescription: PropTypes.func,
  setIsRoomBlock: PropTypes.func,
  selectedTask: PropTypes.object,
  additionalNotes: PropTypes.string,
  setAdditionalNotes: PropTypes.func,
  assignedUser: PropTypes.object,
  setAssignedUsers: PropTypes.func,
  isUrgent: PropTypes.bool,
  shouldDisableInput: PropTypes.bool,
  isLoading: PropTypes.bool,
  setIsUrgent: PropTypes.func,
  handleGoBack: PropTypes.func,
  isRoomBlock: PropTypes.bool,
  roomNumberParsed: PropTypes.array,
};

export default CreateEditTaskForm;
