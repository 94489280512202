import { types } from 'mobx-state-tree';
import { values } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment-timezone';

const Notification = types
  .model('Notification', {
    id: types.string,
    additionalId: types.maybe(types.number),
    additionalType: types.maybe(types.string),
    text: types.string,
    date: types.string,
  })
  .volatile(() => ({
    callback: () => {},
  }))
  .actions((self) => ({
    setCallback(callback) {
      self.callback = callback;
    },
  }));

const NotificationStore = types
  .model('NotificationStore', {
    notifications: types.optional(types.map(Notification), {}),
  })
  .views((self) => ({
    get notificationsAsArray() {
      return values(self.notifications);
    },
  }))
  .actions((self) => ({
    createNotification: ({ additionalId, additionalType, text, callback }) => {
      const existingNotification = self.notificationsAsArray.find(
        (notification) =>
          notification.text === text &&
          notification.additionalId === additionalId &&
          notification.additionalType === additionalType
      );

      if (!existingNotification) {
        const notification = Notification.create({
          id: uuidv4(),
          additionalId,
          additionalType,
          text,
          date: moment()
            .toDate()
            .getTime()
            .toString(),
        });
        notification.setCallback(callback);
        self.notifications.set(notification.id, notification);
      }
    },
    deleteNotification: ({ id }) => {
      self.notifications.delete(id);
    },
  }));

export default NotificationStore;
