import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useStore } from 'src/context/StoreContext';
import ChooseTaskCard from './ChooseTaskCard';
import ChooseTaskSearchBar from './ChooseTaskSearchBar';

const Container = styled('div')`
  display: grid;
  grid-template-rows: 75px 1fr;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 1px solid #8e8e8e;
  border-radius: 3px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 90%;
    height: 100%;
  }
`;

const CardsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-radius: 3px;
  overflow-y: scroll;
  height: 100%;
`;

const ChooseTask = ({
  setTaskDescription,
  selectedTask,
  setSelectedTask,
  isRoomBlock,
}) => {
  const { hotelTaskStore, userStore } = useStore();
  const [search, setSearch] = useState('');
  const { authenticatedUser } = userStore;
  const { languagePreference } = authenticatedUser;

  const searchMatch = (title) => {
    return (
      search === '' ||
      title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  };

  const mostUsedHotelTasks = hotelTaskStore.hotelTasksAsArray.filter(
    (hotelTask) => hotelTask.mostUsed
  );

  const otherHotelTasks = hotelTaskStore.hotelTasksAsArray.filter(
    (hotelTask) => !hotelTask.mostUsed
  );

  const allHotelTasks = mostUsedHotelTasks.concat(otherHotelTasks);

  const hotelTasks = allHotelTasks.map((hotelTask) => {
    return {
      title: hotelTask.description[languagePreference],
      image: hotelTask.imageUrl,
      description: hotelTask.description,
    };
  });

  const customTask = {
    title: 'Create a Custom Task',
    isCustom: true,
  };

  return (
    <Container>
      <ChooseTaskSearchBar
        isRoomBlock={isRoomBlock}
        setSearch={setSearch}
        search={search}
      />
      <CardsContainer>
        {hotelTasks.map((task) => {
          if (searchMatch(task.title)) {
            return (
              <ChooseTaskCard
                setSelectedTask={setSelectedTask}
                setTaskDescription={setTaskDescription}
                key={task.title}
                task={task}
                selectedTask={selectedTask}
              />
            );
          }
          return null;
        })}
        <ChooseTaskCard
          task={customTask}
          setSelectedTask={setSelectedTask}
          setTaskDescription={setTaskDescription}
          isCustom
          customTitle={search}
          selectedTask={selectedTask}
        />
      </CardsContainer>
    </Container>
  );
};

ChooseTask.propTypes = {
  setTaskDescription: PropTypes.func,
  setSelectedTask: PropTypes.func,
  selectedTask: PropTypes.object,
  isRoomBlock: PropTypes.bool,
};

export default ChooseTask;
