import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import moment from 'moment';
import { Checkbox } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { useStore } from 'src/context/StoreContext';
import { COMPLETE_STATUS, INCOMPLETE_STATUS } from 'src/models/TaskStore';

export const URGENT_TEXT = 'URGENT';

export const TASKS_URL = `/tasks`;
export const COMPLETED_TEXT = 'Completed';
export const CREATED_TEXT = 'Created';
export const AGO_TEXT = 'ago';

export const testIds = {
  CREATED_AT: 'CREATED_AT',
  COMPLETED_AT: 'COMPLETED_AT',
};

const TaskContainer = styled('div')`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 75px;
  width: 95%;
  max-width: 400px;
  min-height: 70px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-left: 16px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const Title = styled(Typography)`
  color: #000000;
  text-decoration: ${({ completed }) =>
    completed === 'true' ? 'line-through' : 'none'};
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
`;

const TimeStamp = styled(Typography)`
  color: #666666;
  margin-top: 3px;
`;

const CheckboxContainer = styled('div')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const LoadingSpinner = styled(CircularProgress)`
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
`;

const TaskCard = observer(({ task, setSelectedTask, showRoomAsTitle }) => {
  const {
    id,
    description,
    createdAt,
    completedAt,
    status,
    urgent,
    roomId,
    additionalNotes,
  } = task;
  const { taskStore, roomStore, userStore } = useStore();
  const { tasksToPatch } = taskStore;
  const { authenticatedUser } = userStore;
  const location = useLocation();

  const languagePreference = authenticatedUser
    ? authenticatedUser.languagePreference
    : null;

  const room = roomStore.rooms.get(roomId);

  const toggleStatus = (e) => {
    e.stopPropagation();
    const newStatus =
      status === INCOMPLETE_STATUS ? COMPLETE_STATUS : INCOMPLETE_STATUS;

    const errorNotificationText =
      status === INCOMPLETE_STATUS
        ? `Unable to complete the task for room ${room.number}, Please try again.`
        : `Unable to open the task for room ${room.number}, Please try again.`;

    const taskUpdates = {
      status: newStatus,
    };
    taskStore.patchTask({
      taskId: id,
      options: { body: { task: taskUpdates } },
      errorNotificationText,
    });
  };

  const taskContainer = () => {
    return (
      <TaskContainer
        onClick={setSelectedTask ? () => setSelectedTask(id) : null}
      >
        <TextContainer>
          {urgent && (
            <Typography variant="caption" color="error">
              {URGENT_TEXT}
            </Typography>
          )}
          <Title
            variant="body1"
            completed={(status === COMPLETE_STATUS).toString()}
          >
            {showRoomAsTitle
              ? `Room ${room.number}`
              : description[languagePreference]}
          </Title>
          {additionalNotes ? (
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <Typography variant="body2">Notes: {additionalNotes}</Typography>
            </div>
          ) : null}
          {status === COMPLETE_STATUS && completedAt ? (
            <TimeStamp variant="subtitle2" testid={testIds.COMPLETED_AT}>
              {`${COMPLETED_TEXT} ${moment
                .duration(moment() - moment(completedAt))
                .humanize()} ${AGO_TEXT}`}
            </TimeStamp>
          ) : (
            <TimeStamp variant="subtitle2" testid={testIds.CREATED_AT}>
              {`${CREATED_TEXT} ${moment
                .duration(moment() - moment(createdAt))
                .humanize()} ${AGO_TEXT}`}
            </TimeStamp>
          )}
        </TextContainer>
        <CheckboxContainer onClick={toggleStatus}>
          <Checkbox
            checked={
              task.status === COMPLETE_STATUS || tasksToPatch.includes(task.id)
            }
          />
          {tasksToPatch.includes(task.id) && <LoadingSpinner size={33} />}
        </CheckboxContainer>
      </TaskContainer>
    );
  };

  return (
    <>
      {setSelectedTask ? (
        <> {taskContainer()} </>
      ) : (
        <Link
          to={{
            pathname: `/rooms/${task.roomId}/tasks/${task.id}`,
            state: { background: location },
          }}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {taskContainer()}
        </Link>
      )}
    </>
  );
});

TaskCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    status: PropTypes.string.isRequired,
    urgent: PropTypes.bool,
  }).isRequired,
  setSelectedTask: PropTypes.func,
};

export default TaskCard;
