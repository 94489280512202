import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography, Badge } from '@material-ui/core';

import { VACANCY_STATUS, CLEAN_STATUS, COLORS } from 'src/models/RoomStore';
import { ReactComponent as CleanIcon } from 'src/assets/icons/clean.svg';
import { ReactComponent as DirtyIcon } from 'src/assets/icons/dirty.svg';
import { ReactComponent as BlockedIcon } from 'src/assets/icons/blocked.svg';
import { ReactComponent as InspectIcon } from 'src/assets/icons/inspect.svg';
import { ReactComponent as DoNotDisturbIcon } from 'src/assets/icons/do_not_disturb.svg';

const CLEAN_ICON = 'CLEAN_ICON';
const DIRTY_ICON = 'DIRTY_ICON';
const INSPECT_ICON = 'INSPECT_ICON';
const BLOCKED_ICON = 'BLOCKED_ICON';
const DO_NOT_DISTURB_ICON = 'DO_NOT_DISTURB_ICON';
const TILE = 'TILE';
const RED_BUBBLE = 'RED_BUBBLE';

const Tile = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s;
  width: 85px;
  height: 85px;
  border-radius: 5px;
  padding: 8px;
  background-color: #ffffff;
  border: ${(props) => (props.isShouldInspect ? '3px solid #F9A148' : 'none')};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 0px 2px rgba(0, 0, 0, 0.14);
  text-align: center;
  cursor: pointer;
  overflow: hidden;
`;

const Triangle = styled('div')`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0px;
  width: 0px;
  border-bottom: 55px solid ${({ color }) => color};
  border-left: 60px solid transparent;
  z-index: 0;
`;

const getRoomColor = (vacancyStatus, cleanStatus, doNotDisturb) => {
  if (doNotDisturb) {
    return COLORS.DO_NOT_DISTURB;
  }
  switch (vacancyStatus) {
    case VACANCY_STATUS.BLOCKED:
      return COLORS.BLOCKED;
    default:
      switch (cleanStatus) {
        case CLEAN_STATUS.CLEAN:
          return COLORS.CLEAN;
        case CLEAN_STATUS.SHOULD_INSPECT:
          return COLORS.SHOULD_INSPECT;
        case CLEAN_STATUS.DIRTY:
          return COLORS.DIRTY;
        default:
          return '#000000';
      }
  }
};

const getRoomVacancyText = (vacancyStatus, doNotDisturb) => {
  if (doNotDisturb) {
    return 'Do Not Disturb';
  }
  switch (vacancyStatus) {
    case VACANCY_STATUS.STAYOVER:
      return 'Stayover';
    case VACANCY_STATUS.CHECKOUT:
      return 'Checkout';
    case VACANCY_STATUS.BLOCKED:
      return 'Blocked';
    case VACANCY_STATUS.VACANT:
      return 'Vacant';
    default:
      return '';
  }
};

const getTileIcon = (vacancyStatus, cleanStatus, doNotDisturb) => {
  const style = {
    position: 'absolute',
    bottom: 5,
    right: 5,
    width: 13,
    height: 13,
  };

  if (doNotDisturb) {
    return <DoNotDisturbIcon data-testid={DO_NOT_DISTURB_ICON} style={style} />;
  }

  if (vacancyStatus.toLowerCase() === VACANCY_STATUS.BLOCKED) {
    return <BlockedIcon data-testid={BLOCKED_ICON} style={style} />;
  }

  switch (cleanStatus.toLowerCase()) {
    case CLEAN_STATUS.CLEAN:
      return <CleanIcon data-testid={CLEAN_ICON} style={style} />;
    case CLEAN_STATUS.SHOULD_INSPECT:
      return <InspectIcon data-testid={INSPECT_ICON} style={style} />;
    case CLEAN_STATUS.DIRTY:
      return <DirtyIcon data-testid={DIRTY_ICON} style={style} />;
    default:
      return null;
  }
};

const RoomCard = ({ room, hasTask, incompleteTaskCount, onClick }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <Badge
      badgeContent={incompleteTaskCount}
      invisible={!hasTask}
      data-testid={RED_BUBBLE}
    >
      <Tile
        onClick={handleClick}
        isShouldInspect={room.cleanStatus === CLEAN_STATUS.SHOULD_INSPECT}
      >
        <Typography variant="h5" style={{ fontWeight: 'bold', zIndex: '1' }}>
          {room.number}
        </Typography>
        <Typography variant="caption" style={{ zIndex: '1' }}>
          {getRoomVacancyText(room.vacancyStatus, room.doNotDisturb)}
        </Typography>
        <Triangle
          color={getRoomColor(
            room.vacancyStatus,
            room.cleanStatus,
            room.doNotDisturb
          )}
          data-testid={TILE}
        />
        {getTileIcon(room.vacancyStatus, room.cleanStatus, room.doNotDisturb)}
      </Tile>
    </Badge>
  );
};

export default RoomCard;

RoomCard.propTypes = {
  room: PropTypes.shape({
    number: PropTypes.string,
    id: PropTypes.number,
    vacancyStatus: PropTypes.string,
    cleanStatus: PropTypes.string,
    doNotDisturb: PropTypes.bool,
  }),
  hasTask: PropTypes.bool,
  onClick: PropTypes.func,
  incompleteTaskCount: PropTypes.number,
};
