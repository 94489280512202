import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import { ReactComponent as PencilIcon } from 'src/assets/icons/pencil.svg';
import { COMPLETE_STATUS, INCOMPLETE_STATUS } from 'src/models/TaskStore';
import RoomDetailsTaskList from './RoomDetailsTaskList';

const COMPLETED_TODAY_TEXT = 'Completed Today';
const INCOMPLETE_TEXT = 'Open Tasks';
const COMPLETED_BEFORE_TODAY_TITLE = 'Completed Before Today';
const NEW_TASK_BUTTON_TEXT = 'New Task';
export const INCOMPLETE_TASK_LIST = 'INCOMPLETE_TASK_LIST';
export const COMPLETED_TODAY_TASK_LIST = 'COMPLETED_TODAY_TASK_LIST';

const TaskListContainer = styled('div')`
  position: relative;
  grid-area: taskList;
  display: grid;
  grid-template-rows: 1fr 1fr;
  background-color: #ececec;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow-y: scroll;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: repeat(auto-fill, 425px);
  }
`;

const NewTaskButton = styled(Button)`
  width: 140px;
  height: 35px;
  border-radius: 200px;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 9999;
`;

const RoomDetailsTaskListContainer = ({
  setTaskToEdit,
  setEditTaskOpen,
  incompleteTasks,
  completedTodayTasks,
  completedBeforeTodayTasks,
  setCreateTaskOpen,
  setTaskHistoryOpen,
  taskHistoryOpen,
}) => {
  return (
    <TaskListContainer>
      <NewTaskButton
        onClick={() => setEditTaskOpen(true)}
        variant="contained"
        startIcon={
          <PencilIcon
            width="16px"
            height="16px"
            fill="#ffffff"
            style={{ marginRight: '10px' }}
          />
        }
      >
        {NEW_TASK_BUTTON_TEXT}
      </NewTaskButton>
      <RoomDetailsTaskList
        setTaskToEdit={setTaskToEdit}
        setEditTaskOpen={setEditTaskOpen}
        testId={INCOMPLETE_TASK_LIST}
        tasks={incompleteTasks}
        title={INCOMPLETE_TEXT}
        taskCountColor="#D9465A"
        taskStatus={INCOMPLETE_STATUS}
        setTaskHistoryOpen={setTaskHistoryOpen}
        taskHistoryOpen={taskHistoryOpen}
        isEmptyList={
          completedTodayTasks.length === 0 && incompleteTasks.length === 0
        }
        setCreateTaskOpen={setCreateTaskOpen}
      />
      <RoomDetailsTaskList
        setTaskToEdit={setTaskToEdit}
        setEditTaskOpen={setEditTaskOpen}
        testId={COMPLETED_TODAY_TASK_LIST}
        tasks={completedTodayTasks}
        isEmptyList={
          completedTodayTasks.length === 0 && incompleteTasks.length === 0
        }
        title={COMPLETED_TODAY_TEXT}
        setTaskHistoryOpen={setTaskHistoryOpen}
        taskHistoryOpen={taskHistoryOpen}
        taskStatus={COMPLETE_STATUS}
      />
      {taskHistoryOpen ? (
        <RoomDetailsTaskList
          setTaskToEdit={setTaskToEdit}
          setEditTaskOpen={setEditTaskOpen}
          tasks={completedBeforeTodayTasks}
          isEmptyList={
            completedTodayTasks.length === 0 && incompleteTasks.length === 0
          }
          title={COMPLETED_BEFORE_TODAY_TITLE}
        />
      ) : null}
    </TaskListContainer>
  );
};

RoomDetailsTaskListContainer.propTypes = {
  setTaskToEdit: PropTypes.func,
  setEditTaskOpen: PropTypes.func,
  incompleteTasks: PropTypes.array,
  completedTodayTasks: PropTypes.array,
  completedBeforeTodayTasks: PropTypes.array,
  setCreateTaskOpen: PropTypes.func,
  setTaskHistoryOpen: PropTypes.func,
  taskHistoryOpen: PropTypes.bool,
};

export default RoomDetailsTaskListContainer;
