import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { Tabs, Tab } from '@material-ui/core';

import { useStore } from 'src/context/StoreContext';
import { INCOMPLETE_STATUS, COMPLETE_STATUS } from 'src/models/TaskStore';
import TaskList from './components/TaskList';
import TaskScreenHeader from './components/TaskScreenHeader';

const OPEN_TASKS_TAB_LABEL = 'OPEN';
const COMPLETE_TASKS_TAB_LABEL = 'COMPLETE';

const Container = styled('div')`
  grid-area: tasks_panel;
  display: grid;
  grid-template-rows: 100px 50px minmax(0, 1fr);
  grid-template-areas:
    'header'
    'tabs'
    'tasks';
  border-radius: 5px;
  border: 1px solid #8e8e8e;
  overflow: hidden;
`;

const ROOM_SORT_VALUE = 'room';

const TaskPanel = observer(({ setIsModalOpen }) => {
  const [selectedTaskStatus, setSelectedTaskStatus] = useState(
    INCOMPLETE_STATUS
  );
  const [sortOption, setSortOption] = useState(ROOM_SORT_VALUE);
  const {
    fetchAllTasks,
    isFetchingAll,
    networkError,
    tasksAsArray,
    completedTodayTasks,
  } = useStore().taskStore;
  const { selectedHotelId } = useStore().hotelStore;

  let taskCount;
  let urgentTaskCount;

  if (selectedTaskStatus === INCOMPLETE_STATUS) {
    taskCount = tasksAsArray.filter(
      (task) => task.status && task.status === selectedTaskStatus
    ).length;
    urgentTaskCount = tasksAsArray.filter(
      (task) => task.status && task.status === selectedTaskStatus && task.urgent
    ).length;
  } else {
    taskCount = completedTodayTasks.length;
    urgentTaskCount = completedTodayTasks.filter((task) => task.urgent).length;
  }

  useEffect(() => {
    if (selectedHotelId) {
      fetchAllTasks(selectedHotelId);
    }
  }, [selectedHotelId, fetchAllTasks]);

  return (
    <Container>
      <TaskScreenHeader
        setSortOption={setSortOption}
        sortOption={sortOption}
        setIsModalOpen={setIsModalOpen}
        selectedTaskStatus={selectedTaskStatus}
        taskCount={taskCount}
        urgentTaskCount={urgentTaskCount}
      />
      <Tabs
        variant="fullWidth"
        value={selectedTaskStatus}
        onChange={(_, value) => setSelectedTaskStatus(value)}
      >
        <Tab label={OPEN_TASKS_TAB_LABEL} value={INCOMPLETE_STATUS} />
        <Tab label={COMPLETE_TASKS_TAB_LABEL} value={COMPLETE_STATUS} />
      </Tabs>
      <TaskList
        sortOption={sortOption}
        networkError={networkError}
        selectedTaskStatus={selectedTaskStatus}
        taskCount={taskCount}
        isFetchingAll={isFetchingAll}
      />
    </Container>
  );
});

export default TaskPanel;
