import React, { useState } from 'react';
import styled from '@emotion/styled';

import BottomNavigationBar from 'src/components/BottomNavigationBar/BottomNavigationBar';
import TaskPanel from 'src/screens/Dashboard/Tasks/TaskScreen/TaskScreen';
import CreateTaskModal from 'src/screens/Dashboard/Tasks/CreateEditTaskScreen/CreateTaskModal';

const Container = styled('div')`
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'tasks_panel'
    'navbar';
`;

const TaskScreenMobile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Container>
      <TaskPanel setIsModalOpen={setIsModalOpen} />
      <BottomNavigationBar />
      <CreateTaskModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Container>
  );
};

export default TaskScreenMobile;
