import React from 'react';

const RoomFiltersContext = React.createContext({
  filters: null,
  dynamicFilterCounts: null,
  clearFilters: () => {},
  // eslint-disable-next-line no-unused-vars
  toggleFilter: (filterType) => {},
  // eslint-disable-next-line no-unused-vars
  isFilterDisabled: (filterType) => {},
});

export const useRoomFilters = () => {
  const roomFilters = React.useContext(RoomFiltersContext);
  if (!roomFilters) {
    throw new Error(
      'useRoomFilters must be used within a RoomFiltersProvider.'
    );
  }
  return roomFilters;
};

export default RoomFiltersContext;
