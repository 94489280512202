import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { ReactComponent as PencilIcon } from 'src/assets/icons/pencil.svg';
import { INCOMPLETE_STATUS } from 'src/models/TaskStore';
import TaskSortDropdown from './TaskSortDropdown';

const NEW_TASK_BUTTON_TEXT = 'NEW TASK';
const SORT_LABEL = 'Sort:';

const Header = styled('div')`
  display: grid;
  grid-template-columns: minmax(150px, 200px) minmax(125px, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'title  button'
    'sort   button';
  padding: 20px;
  border-bottom: 1px solid rgba(142, 142, 142, 0.4);
  height: 100px;
  grid-gap: 5px;
`;

const TaskScreenHeader = ({
  selectedTaskStatus,
  setIsModalOpen,
  taskCount,
  sortOption,
  setSortOption,
}) => {
  const theme = useTheme();
  const TASKS_HEADER_LABEL =
    selectedTaskStatus === INCOMPLETE_STATUS ? 'Open' : 'Completed';

  return (
    <Header>
      <Typography
        variant="h5"
        style={{ gridArea: 'title', fontWeight: 'bold' }}
      >
        {taskCount} {TASKS_HEADER_LABEL}
      </Typography>
      <Button
        variant="contained"
        startIcon={
          <PencilIcon
            width={12}
            height={12}
            fill={theme.palette.primary.contrastText}
          />
        }
        onClick={() => setIsModalOpen(true)}
        style={{
          gridArea: 'button',
          height: '50px',
          alignSelf: 'center',
          justifySelf: 'end',
        }}
      >
        {NEW_TASK_BUTTON_TEXT}
      </Button>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gridArea: 'sort',
        }}
      >
        <Typography variant="subtitle2">{SORT_LABEL}</Typography>
        <TaskSortDropdown
          setSortOption={setSortOption}
          sortOption={sortOption}
        />
      </div>
    </Header>
  );
};

TaskScreenHeader.propTypes = {
  selectedTaskStatus: PropTypes.string,
  setIsModalOpen: PropTypes.func,
  taskCount: PropTypes.number,
  sortOption: PropTypes.string,
  setSortOption: PropTypes.func,
};

export default TaskScreenHeader;
