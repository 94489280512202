import React, { useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { Drawer } from '@material-ui/core';

import useBreakpoints from 'src/utils/useBreakpoints';
import { useStore } from 'src/context/StoreContext';
import { TEXT_MESSAGE_TYPES } from 'src/models/TextMessageStore';
import GuestMessageDetails from './components/GuestMessageDetails';
import GuestMessageScreenHeader from './components/GuestMessageScreenHeader';
import GuestMessagePreview from './components/GuestMessagePreview';

const Container = styled('div')`
  grid-area: messages;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
`;

const MainContentContainer = styled('div')`
  overflow-x: hidden;
  overflow-y: scroll;
  width: 450px;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

const GuestMessageScreen = observer(({ isDrawerOpen, setIsDrawerOpen }) => {
  const { textMessageStore, roomStore, hotelStore, userStore } = useStore();
  const { rooms } = roomStore;
  const { authenticatedUser } = userStore;
  const { timeZone } = hotelStore;
  const [selectedGuestId, setSelectedGuestId] = useState(null);
  const { isSmallScreen } = useBreakpoints();

  const guestMessagePreviews = [];

  if (!selectedGuestId) {
    textMessageStore.textMessagesByGuests.forEach((textMessages, guestId) => {
      let numberOfUnreadMessages = 0;
      let latestMessage = null;

      textMessages.forEach((message) => {
        if (
          !message.read &&
          message.type === TEXT_MESSAGE_TYPES.INBOUND_TEXT_MESSAGE
        ) {
          numberOfUnreadMessages++;
        }
        if (!latestMessage) {
          latestMessage = message;
        }
      });

      const onClick = () => {
        textMessages.forEach((textMessage) =>
          textMessageStore.setTextMessageAsRead(textMessage.id)
        );
        setSelectedGuestId(guestId);
      };

      guestMessagePreviews.push(
        <GuestMessagePreview
          // eslint-disable-next-line react/no-array-index-key
          key={guestId}
          textMessage={latestMessage}
          numberOfUnreadMessages={numberOfUnreadMessages}
          onClick={onClick}
          setSelectedGuestId={setSelectedGuestId}
          rooms={rooms}
        />
      );
    });
  }

  const content = (
    <Container>
      <GuestMessageScreenHeader
        setIsDrawerOpen={setIsDrawerOpen}
        setSelectedGuestId={setSelectedGuestId}
      />
      <MainContentContainer>
        {selectedGuestId ? (
          <GuestMessageDetails
            networkError={textMessageStore.networkError}
            authenticatedUser={authenticatedUser}
            createTextMessage={textMessageStore.createTextMessage}
            textMessages={textMessageStore.textMessagesByGuests.get(
              selectedGuestId
            )}
            setSelectedGuestId={setSelectedGuestId}
            rooms={rooms}
            timeZone={timeZone}
          />
        ) : (
          guestMessagePreviews
        )}
      </MainContentContainer>
    </Container>
  );

  return isSmallScreen ? (
    content
  ) : (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      BackdropProps={{
        invisible: true,
      }}
      elevation={4}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
    >
      {content}
    </Drawer>
  );
});

export default GuestMessageScreen;
